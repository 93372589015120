export const CREATE_DAO = 0.0052;
export const CREATE_PROJECT = 0.002932894;
// export const SEND_TOKEN_TO_TREASURY = 0.00002848;
//export const CREATE_ADD_MEMBER = 0.00063351968;
// export const VOTE_ADD_MEMBER = 0.0000384;
// export const FINALIZE_ADD_MEMBER = 0.0001088;
//export const CREATE_REMOVE_MEMBER = 0.0006112864;
// export const VOTE_REMOVE_MEMBER = 0.00002848;
// export const FINALIZE_REMOVE_MEMBER = 0.000064;
//export const CREATE_GOVERNANCE_PROPOSAL = 0.000628672;
// export const VOTE_GOVERNANCE_PROPOSAL = 0.00004242976;
// export const FINALIZE_GOVERNANCE_PROPOSAL = 0.000032;
//export const CREATE_ASSETS_PROPOSAL = 0.000632;
// export const VOTE_ASSETS_PROPOSAL = 0.000032;
// export const FINALIZE_ASSETS_PROPOSAL = 0.000032;
//export const CREATE_SUBSCRIPTION_PROPOSAL = 0.000632;
// export const CREATE_SUBSCRIPTION_TO_SEND_TOKEN_PROPOSAL = 0.000032;
// export const VOTE_SUBSCRIPTION_PROPOSAL = 0.000032;
// export const FINALIZE_SUBSCRIPTION_PROPOSAL = 0.000032;

export const CREATE_PROPOSAL_PAYABLE_VALUE = "0.0006";
