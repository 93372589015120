
export const getAddress = (address) => {
    const mainNetChainId = 97
    const chainId = localStorage.getItem("selected_chain") || mainNetChainId
    return address[chainId] ? address[chainId] : address[mainNetChainId]
}






