import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import store from "../../../../redux/store";
import { updateSpinner } from "../../../../redux/actions";
import { getWeb3 } from "../../../../helpers/currentWalletHelper";
import multiSigv2Abi from "./../../../../Config/abis/EquinoxMain.json";
import { CommonModal } from "../../../../components/modals";
import { getMemberVotedList, getOrg } from "../../../../services/dashboard";
import DetailModal from "../../../../components/DetailModal";
import { getAddedMembers, getMultiSigWallet } from "./../../../../helpers/getterFunctions"
import { getAccount } from "./../../../../helpers/currentWalletHelper"
import { getContractInstance } from "./../../../../components/Accountmodal"
import contracts from '../../../../Config/contracts';
import deployerAbi from "./../../../../Config/abis/multisigDeployer.json"
import { getAddress } from '../../../../helpers/addressHelper';

function AddMemberRequest(props) {
    const { org, auth } = props;
    const [currentValues, setCurrentValues] = useState({});
    const [approveModal, setApproveModal] = useState(false);
    const [dissApproveModal, setDissApproveModal] = useState(false);
    const [finalizeModal, setFinalizeModal] = useState(false);
    const [voters, setVoter] = useState([]);
    const [view, setView] = useState(false);
    const [votingLoading, setVotingLoading] = useState(false);
    const [message, setMessage] = useState("Member has been removed");
    const [open, setOpen] = useState(false);
    const [requests, setRequests] = useState()

    useEffect(() => {
        if (org, auth) {
            getMemberVotedList(auth.id);
        }
    }, [org, auth]);

    useEffect(() => {
        const fetch = async () => {
            let { pendingRequests } = await getAddedMembers()
            setRequests(pendingRequests)
        }
        fetch()
    }, [])

 

    const getVotingList = async (pro, type) => {
        if (org && org?.members?.length > 0) {
            setVotingLoading(true);

            setVoter([]);
            org.members
                .filter((member) => member.is_active === 1)
                .forEach(async (element) => {
                    let objectOfMember = {};
                    axios
                        .get(
                            `${process.env.REACT_APP_API_URL}/get_vote_list_by_type/${type}/${element.id}`
                        )
                        .then(({ data }) => {
                            console.log(data, pro);
                            if (data.status === "success") {
                                const matchedVales = data.response.filter(
                                    (item) => item.proposal_id === pro?.id
                                );
                                console.log("Matched", matchedVales);
                                objectOfMember = element;
                                if (matchedVales.length > 0) {
                                    objectOfMember.voted = "Yes";
                                } else {
                                    objectOfMember.voted = "No";
                                }
                            }
                            if (data.status === "error") {
                                objectOfMember = element;
                                objectOfMember.voted = "No";
                            }

                            setVoter((prev) => [...prev, objectOfMember]);
                            setVotingLoading(false);
                        })
                        .catch((error) => {
                            setVotingLoading(false);
                            console.log(error);
                            // setLoading(false);
                        });
                });
            console.log("calling");
            // setLoading(false);
            setView(true);
        }
    };

    //ADD MEMBER _FUNCTIONS
    const approve = async (id, to) => {
        store.dispatch(updateSpinner(true));
        let account = await getAccount();
        let multiSigAddr = getMultiSigWallet();
        let contract = await getContractInstance(multiSigAddr,multiSigv2Abi?.abi);
       
        console.log("INdex", id);
        console.log("approving", id, auth?.id);
        const isNotAbleTosign = await contract.methods
            .hasAddMemberProposolSigned(id, account)
            .call();

        console.log(isNotAbleTosign);
        const isSigned = await contract.methods
            .isAddMemberProposalDisapproved(id, account)
            .call();
        console.log(isSigned, '-------isSigned------');
        if (isSigned) {
            store.dispatch(updateSpinner(false));
            alert("Member is Disapproved");
            store.dispatch(updateSpinner(false));
        } else {
            if (!isNotAbleTosign) {
                store.dispatch(updateSpinner(true));
                 contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);
       
                await contract.methods
                    .signAddMemberProposal(id,multiSigAddr)
                    .send({ from: account })
                    .on("error", (error) => {
                        console.log(error);
                        store.dispatch(updateSpinner(false));
                    })
                    .then(async (result) => {
                        store.dispatch(updateSpinner(false));
                        setMessage("Your Vote has been registered");
                        setOpen(true);
                        setTimeout(() => {
                            setOpen(false);
                        }, 5000);
                        if (auth && auth.org_id) getOrg(auth.org_id);
                    });
            } else {
                alert("Your vote has already been registered.");
                store.dispatch(updateSpinner(false));
            }
        }
    };

    const finalizeAddMember = async (id, memberAddress) => {
        store.dispatch(updateSpinner(true));
        let account = await getAccount();
        let multiSigAddr = getMultiSigWallet()
        let contract = await getContractInstance(
            multiSigAddr,
            multiSigv2Abi.abi
        );

        const isAbleTosign = await contract.methods
            .addMemberProposalRequirementMet(id)
            .call();
        const isSigned = await contract.methods
            .isAddMemberProposalDisapproved(id, account)
            .call();
        console.log(isAbleTosign, memberAddress);
        if (isSigned) {
            store.dispatch(updateSpinner(false));
            alert("Member is Disapproved");
            store.dispatch(updateSpinner(false));
        } else {
            if (isAbleTosign) {
                store.dispatch(updateSpinner(true));
                contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);
       
                await contract.methods
                    .finalizeAddMemberProposal(id,multiSigAddr)
                    .send({ from: account })
                    .on("error", (error) => {
                        console.log(error);
                        store.dispatch(updateSpinner(false));
                    })

                    .then((res) => {
                        store.dispatch(updateSpinner(false));
                        setMessage("Member is Finalized successfully");
                        setOpen(true);
                        setTimeout(() => {
                            setOpen(false);
                        }, 5000);
                        if (auth && auth.org_id) getOrg(auth.org_id);
                    });

            } else {
                alert("This Proposal hasn't reached minimum votes required to Finalize.");
                store.dispatch(updateSpinner(false));
            }
        }
    };
    // console.log(auth.org_id);
    const disApprove = async (id, to) => {
        store.dispatch(updateSpinner(true));
        let account = await getAccount();
        let multiSigAddr = getMultiSigWallet();
        let contract = await getContractInstance(
            multiSigAddr,
            multiSigv2Abi.abi
        );
        console.log("DISAPPROVE", id, auth?.id, id);
        const isSigned = await contract.methods
            .isAddMemberProposalDisapproved(id, account)
            .call();
        const isApproved = await contract.methods
            .hasAddMemberProposolSigned(id, account)
            .call();
        console.log(isSigned);
        if (isApproved) {
            store.dispatch(updateSpinner(false));
            alert("You already voted");
            store.dispatch(updateSpinner(false));
        } else {
            if (!isSigned) {
                store.dispatch(updateSpinner(true));
                contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);
       
                await contract.methods
                    .disapproveAddMemberProposal(id,multiSigAddr)
                    .send({ from: account })
                    .on("error", (error) => {
                        console.log(error);
                        store.dispatch(updateSpinner(false));
                    })
                    .then(async (res) => {
                        store.dispatch(updateSpinner(false));
                        setMessage("Your Vote has been registered");
                        setOpen(true);
                        setTimeout(() => {
                            setOpen(false);
                        }, 5000);

                        if (auth && auth.org_id) getOrg(auth.org_id);
                    });
            } else {
                alert("Already Dissapproved!");
                store.dispatch(updateSpinner(false));
            }
        }
    };

    const approveHandler = (values) => {
        console.log(values);
        setApproveModal(false);
        approve(values.reqId, values.member);
    };

    const dissApproveHandler = (values) => {
        console.log(values);
        setDissApproveModal(false);
        disApprove(values.reqId, values.member);
    };

    const finalizeHandler = (values) => {
        console.log(values);
        setFinalizeModal(false);
        finalizeAddMember(values.reqId, values.member);
    };
    return (
        <div>
            {requests && requests?.length > 0 && requests.map((pro, index) => {
                return (
                    <div className='welcome-card rounded-lg p-6 mb-6 text-black' key={pro.id}>
                        <div className='flex flex-wrap gap-6 justify-between'>
                            <div>
                                Name: <b>{pro.member_name}</b>
                            </div>
                            <div>
                                Wallet Address: <b>{pro.member}</b>
                            </div>
                            <div>
                                {!pro.finalized && (
                                    <span
                                        onClick={() => {
                                            setCurrentValues(pro);
                                            setApproveModal(true);
                                        }}
                                        className="approve cursor-pointer"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M5 12l5 5l10 -10"></path>
                                        </svg>
                                    </span>
                                )}
                            </div>
                            <div>
                                {!pro.finalized && (
                                    <span
                                        onClick={() => {
                                            setCurrentValues(pro);
                                            setDissApproveModal(true);
                                        }}
                                        className="approve cursor-pointer"
                                    >
                                        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M18 6l-12 12"></path>
                                            <path d="M6 6l12 12"></path>
                                        </svg>
                                    </span>
                                )}
                            </div>
                            <div className='flex gap-3'>
                                {!pro.finalized && (<Button type='primary' className='grad-btn border-0' onClick={() => {
                                    setCurrentValues(pro);
                                    setFinalizeModal(true);
                                }}>Finalize</Button>)}
                                {/* <Button type='primary' className='grad-btn border-0' onClick={() => {
                                    setCurrentValues(pro);
                                    getVotingList(pro, 2);
                                }}>View</Button> */}
                            </div>
                        </div>
                    </div>
                )
            })
            }
            <ConfirmModal
                title="Are you sure you want to Approve!"
                open={approveModal}
                setOpen={setApproveModal}
                handler={approveHandler}
                currentValues={currentValues}
            />
            <ConfirmModal
                title="Are you sure you want to Dissapprove!"
                open={dissApproveModal}
                setOpen={setDissApproveModal}
                handler={dissApproveHandler}
                currentValues={currentValues}
            />
            <ConfirmModal
                title="Are you sure you want to Finalize!"
                open={finalizeModal}
                setOpen={setFinalizeModal}
                handler={finalizeHandler}
                currentValues={currentValues}
            />
            <CommonModal
                message={message}
                note=""
                note_bracket=""
                open={open}
                setOpen={setOpen}
            />
            <DetailModal
                open={view}
                setOpen={setView}
                handleOpen={() => setView(true)}
                handleClose={() => setView(false)}
                voters={voters}
                votingLoading={votingLoading}
            />
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        org: state.org,
        auth: state.auth
    };
};

export default connect(mapStateToProps)(AddMemberRequest);