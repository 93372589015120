import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainLayout from './layout/main-layout/MainLayout';
import { getMe } from './services/dashboard';

const App = (props) => {
  useEffect(() => {
    const account = localStorage.getItem("eqx_connected_account");
    // if (account) {
    //   getMe(account);
    // }
  }, []);
  return (
    <MainLayout />
  );
};

export default App;