import React from 'react';
import DashboardLayout from '../../layout/dashboard-layout/DashboardLayout';

const Dashboard = () => {

    return (
        <>
            <DashboardLayout />
        </>
    );
};

export default Dashboard;