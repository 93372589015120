import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getAddedMembers } from '../../../../helpers/getterFunctions';

function AddMemberFailed(props) {
    const { org } = props;
    const [members, setMembers] = useState()
    useEffect(() => {
        const fetch = async () => {
            let _members = await getAddedMembers()
            setMembers(_members?.disapprovedRequests)
        }
        fetch()
    }, [])

    return (
        <div>
            {members &&
                members.length > 0 &&
                members.map((pro, index) => {
                    return (
                        <div className='welcome-card rounded-lg p-6 mb-6 text-black' key={pro.id}>
                            <div className='flex gap-6 justify-between'>
                                <div>
                                    Name: {pro.member_name}
                                </div>
                                <div>
                                    Wallet Address: {pro.member}
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        org: state.org,
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(AddMemberFailed);