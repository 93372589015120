import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Space } from 'antd';
import { addOrgFormData } from '../../../redux/actions';
import { getWeb3 } from "../../../helpers/currentWalletHelper";
import MultiSig from "../../../Config/abis/EquinoxMain.json";
import GToken from "../../../Config/abis/GToken.json";
import Eq from "../../../Config/abis/EquinoxToken.json";
import { CREATE_DAO } from "../../../utils";
import { getMe } from '../../../services/dashboard';
import Web3 from "web3";
import {
  AwaitingApproval,
  MultiSignature,
  ContinuePay,
  GasError,
  DuplicateError,
} from "../../../components/modals";
import { connect } from 'react-redux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getMultiSigWallet } from '../../../helpers/getterFunctions';
import { getContractInstance } from '../../../components/Accountmodal';
import contracts from '../../../Config/contracts';
import deployerAbi from "./../../../Config/abis/multisigDeployer.json"
import { getAddress } from '../../../helpers/addressHelper';

function TreasuryStepThird(props) {
  const [wallets, setWallets] = useState([]);
  const eqxAdd = "0x54040960e09fb9f1dd533d4465505ba558693ad6"; // fetch this address (in this file and in org.jsx file) form pages/Config/contracts.js
  const [multiSigAdd, setMultiSigAdd] = useState("");
  const [awaiting, setAwaiting] = useState(false);
  const [multiSign, setMultiSign] = useState(false);
  const [pay, setPay] = useState(false);
  const [gasError, setGasError] = useState(false);
  const [duplicate, setDuplicate] = useState(false);
  const [form] = Form.useForm();
  useEffect(() => {
    form.resetFields();
  }, [props.walletInfo?.wallet, props.orgFormdata?.email])
  const onFinish = async (fields) => {
    var values = fields.members;
    // let hasDuplicates =
    //   values.map((v) => v.email.toLowerCase()).length >
    //     new Set(values.map((v) => v.email.toLowerCase())).size
    //     ? true
    //     : false;
    // if (hasDuplicates) {
    //   message.error("Duplicate email entry found.!!");
    //   // alert("Duplicate email entry found.!!");
    //   return;
    // }

    let hasDuplicatewallet =
      values.map((v) => v.wallet).length >
        new Set(values.map((v) => v.wallet)).size
        ? true
        : false;

    if (hasDuplicatewallet) {
      message.error("Duplicate wallet entry found.!!");
      // alert("");
      return;
    }
    props.dispatch(addOrgFormData(fields));
    deploy(fields.members);
  };
  const addOrg = (member) => {
    const skipFields = ["eqxBln", "mobile_otp", "kyc"];
    const formData = new FormData();
    for (const [key, value] of Object.entries(props.orgFormdata)) {
      if (!skipFields.includes(key)) {
        if (key === "wallet") {
          formData.append("wallet_address", value);
        } else {
          formData.append(key, value);
        }
      }
    }
    member.forEach((mem, index) => {
      if (index > 0) {
        formData.append("member_name[]", mem.name);
        formData.append("member_wallet_address[]", mem.wallet);
        formData.append("member_email[]", mem.email);
      }
    });
    const multisigAddress = localStorage.getItem(props.walletInfo.wallet);
    formData.append("multisig_address", multisigAddress);
    props.nextStep();
    // axios
    //   .post(`${process.env.REACT_APP_API_URL}/add_org`, formData)
    //   .then((res) => {
    //     console.log(res);
    const account = sessionStorage.getItem("selected_account");
    if (account) {
      getMe(account);
    }
    props.nextStep();
    // })
    // .catch((err) => {
    //   console.log(err);
    //   setDuplicate(true);
    // });
  };

  const deploy = async (member) => {


    let web3 = await getWeb3();
    if (member?.length < 3) {
      alert("Minimum 3 members required")
      return
    }
    setAwaiting(true);
    let contract = new web3.eth.Contract(MultiSig.abi); //MultiSig
    console.log("contract", contract)
    let gContract = new web3.eth.Contract(GToken.abi);

    let eqxContract = new web3.eth.Contract(Eq.abi, eqxAdd);
    let accounts = await web3.eth.getAccounts();
    console.log("accounts", accounts)
    let repeated = false;
    member.forEach((mem) => {
      if (wallets.includes(mem.wallet)) {
        // alert("Member already exist")
        setAwaiting(false);
        repeated = true
        setWallets([])
        return
      }
      wallets.push(mem.wallet);
    });
    if (repeated) {
      return;
    }
    try {
      let deployer = await getContractInstance(getAddress(contracts?.multisigDeployer), deployerAbi)
      console.log("deployer", deployer)
      // this will deploy MultiSig contract that will give _address in response
      // 100 equinox will deposited to multisig address at the end
      // multisig contract address against user wallet address should be saved to databas at this step
      // because user has deposited his 100eqx at multisig
      const gasPrice = await web3.eth.getGasPrice();
      const amountToPay = Web3.utils.toWei(`${CREATE_DAO}`, "ether");
      let gasLimit
      // try {
      //    gasLimit = await web3.eth.estimateGas({
      //     from: localStorage.getItem("eqx_connected_account"),
      //     to: process.env.REACT_APP_OWNER_ADDRESS,
      //     value: amountToPay, // If you're interacting with a contract, include the contract data
      //   });
      // }
      // catch (err) {
      //   console.log("err", err)
      // }
      // console.log("gasLimit", gasLimit)

      // gasLimit = gasLimit + 100000;
      // gasLimit = Math.ceil(gasLimit)
      // try {
      //   const tnx = await web3.eth.sendTransaction({
      //     from: localStorage.getItem("eqx_connected_account"),
      //     to: process.env.REACT_APP_OWNER_ADDRESS,
      //     value: amountToPay,
      //     gas: gasLimit,
      //     gasPrice
      //   });
      //   if (!tnx) return;
      //   console.log(tnx);
      // }
      // catch (err) {
      //   console.log("err", err)
      //   if (err.code == "4001") {
      //     alert("User denied");

      //   }
      //   setAwaiting(false);
      //   setPay(false);
      //   setWallets([])
      // }

      // let gasEstimate = await deployer.methods.deploy([wallets], eqxAdd,{value:web3.utils.toWei("0.0001")}).estimateGas()
      // console.log("gaslimit", gasLimit)
      // const gasEstimate = await contract.deploy({
      //   data: MultiSig.bytecode,
      //   arguments: [wallets, eqxAdd], // constructor arguments
      // }).estimateGas();
      // gasLimit = gasEstimate + 100000;
      await deployer.methods.
        deploy(
          wallets, eqxAdd
        )
        .send({
          from: localStorage.getItem("eqx_connected_account"),
          // gas: gasLimit,
          value:web3.utils.toWei("0.0001")
          // gasPrice

        })
        .on("error", (err) => {
          console.log(err);
          setGasError(true);
        })
        .then(async (receipt) => {
          console.log("receipt._address", receipt)
          localStorage.setItem(localStorage.getItem("eqx_connected_account"), receipt._address);
          localStorage.setItem(getMultiSigWallet() + "-wallets", member?.toString())

          let _members = localStorage.getItem(getMultiSigWallet() + "-wallets")
          console.log("members", JSON.parse(_members))
          setAwaiting(false);
          setPay(true);
          addOrg(member);
          console.log(receipt);
          setPay(false);
        }).
        catch((Err) => {
          if (Err.code == "4001") {
            alert("User denied");

          }
          setAwaiting(false);
          setPay(false);
          setWallets([])
        });

    } catch (error) {
      alert("Error", error.message);
      // setGasError(true);

      console.log(error);
      setAwaiting(false);
      // setWallets([])
      setPay(false);
      // setGasError(true);
    }
  };

  return (
    <div className='mt-5 flex flex-col items-center justify-center'>
      <div className=' mb-12'>
        <h1 className='text-2xl font-bold mb-4'>
          STEP 3
        </h1>
        <p className='text-base'>
          Deployer KYC
        </p>
      </div>
      <div className='form w-full lg:w-1/2 welcome-card rounded-lg p-6'>
        <Form
          name="dynamic_form_nest_item"
          onFinish={onFinish}
          autoComplete="off"
          layout='vertical'
          initialValues={{
            members: [
              {
                wallet: props.walletInfo.wallet,
                name: props.orgFormdata?.deployer_name,
                email: props.orgFormdata?.email,
              },
              // {
              //   wallet: '',
              //   name: '',
              //   email: '',
              // },
              // {
              //   wallet: '',
              //   name: '',
              //   email: '',
              // },
            ],
          }}
          form={form}
        >
          <Form.List name="members">
            {(fields, { add, remove }) => (
              <>
                {fields.map(({ key, name, ...restField }, i) => (
                  <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                    <Form.Item
                      {...restField}
                      name={[name, 'wallet']}
                      validateTrigger="onBlur"
                    // rules={[
                    //   { 
                    //     required: true,
                    //     message: 'Wallet is required'
                    //   },
                    //   {
                    //     message: 'Wallet alreasy exist',
                    //     validator: verifyWallet
                    // }
                    // ]}

                    >
                      <Input placeholder="Wallet" disabled={i === 0} />
                    </Form.Item>
                    {/* <Form.Item
                      {...restField}
                      name={[name, 'name']}
                      // rules={[{ required: true, message: 'Name is required' }]}
                    >
                      <Input placeholder="Name" disabled={i === 0} />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      name={[name, 'email']}
                      validateTrigger="onBlur"
                      rules={[
                        {
                          // required: true,
                          // message: 'Email is required'
                        },
                        {
                          type: 'email',
                          // message: 'Email is invalid'
                        },

                      ]}
                    >
                      <Input placeholder="Email" disabled={i === 0} />
                    </Form.Item> */}
                    {i !== 0 && <MinusCircleOutlined onClick={() => remove(name)} />}
                  </Space>
                ))}
                <Form.Item>
                  <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                    Add Member
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
          <div className='flex'>
            <Button
              className='mx-0 flex gap-1 mx-auto bordered border-gray-400 text-gray-400' type="primary"
              onClick={() => props.previousStep()}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-narrow-left" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M5 12l14 0"></path>
                <path d="M5 12l4 4"></path>
                <path d="M5 12l4 -4"></path>
              </svg>
              Previous
            </Button>
            <Button type="primary" htmlType="submit" className='ml-0 flex gap-1 mx-auto grad-btn border-0'>
              Deployy
            </Button>
          </div>
        </Form>
      </div>
      <AwaitingApproval open={awaiting} setOpen={setAwaiting} />
      <GasError open={gasError} setOpen={setGasError} />
      <ContinuePay open={pay} setOpen={setPay} />
      <MultiSignature open={multiSign} setOpen={setMultiSign} />
      <DuplicateError open={duplicate} setOpen={setDuplicate} />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    orgFormdata: state.orgFormdata,
  };
};

export default connect(mapStateToProps)(TreasuryStepThird);