import { Breadcrumb, Button, Form, Input, message, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import axios from "axios";
import { getAccount, getWeb3 } from "../../../../helpers/currentWalletHelper";
import MultiSig from "../../../../Config/abis/EquinoxMain.json";
import { updateSpinner } from "../../../../redux/actions";
import store from "../../../../redux/store";
import {
    CREATE_PROPOSAL_PAYABLE_VALUE,
    //CREATE_REMOVE_MEMBER,
} from "../../../../utils";
import { getMultiSigWallet, getTeamMembers } from '../../../../helpers/getterFunctions';
import { getContractInstance } from '../../../../components/Accountmodal';
import contracts from '../../../../Config/contracts';
import deployerAbi from "./../../../../Config/abis/multisigDeployer.json"
import { getAddress } from '../../../../helpers/addressHelper';

function RemoveMembers(props) {
    const { org } = props;
    const { Option } = Select;
    const navigate = useNavigate();
    const onFinish = async (values) => {
        await new Promise((r) => setTimeout(r, 500));
        removeMember(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
        message.error('Submit success!');
    };
    const [form] = Form.useForm();
    const [members, setMembers] = useState()
    useEffect(() => {
        const fetch = async () => {
            let _members = await getTeamMembers()
            setMembers(_members)
        }
        fetch()
    }, [])
    const removeMember = async (values) => {
        store.dispatch(updateSpinner(true));
        const web3 = await getWeb3();
        const multiSigAddr = getMultiSigWallet();
        const account = await getAccount();
        const contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);
        
        console.log("WORKING ON REMOVE MEMBERS");
        console.log(values);
        const formData = values;
        formData.org_id = org?.org?.id;
       
        await contract.methods
            .removeMemberProposal(values?.member_wallet_address,multiSigAddr)
            .send({
                from: account,
                value: web3.utils.toWei(CREATE_PROPOSAL_PAYABLE_VALUE, "ether"),
            })
            .on("error", (error) => console.log(error))
            .then((result) => {
               
                store.dispatch(updateSpinner(false));

                message.success('Remove Member Proposal Initialised')
                setTimeout(() => {
                    navigate("/dashboard/members");
                }, 1000);
            });
    };
    const handleChange = (value) => {
        form.setFieldValue("member_wallet_address", value);
        if (org && org.members && org.members.length) {
            const index = org.members.findIndex(
                (mem) => mem.wallet_address === value
            );
            if (index >= 0) {

                form.setFieldValue("member_name", org.members[index].member_name);
                form.setFieldValue("member_email", org.members[index].email);
            }
        }
    };
  
    return (
        <div>
            <div className='mb-4 text-white'>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to='/dashboard'>Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className=''>  <Link to='/dashboard/members'>Members</Link></Breadcrumb.Item>
                    <Breadcrumb.Item className='font-bold text-pink-500'>Remove</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div>
                <h1 className='font-bold text-xl mb-2'>
                    Remove EQ Vault Member
                </h1>
                <p className='mb-6 w-3/5 lg:width-full'>
                    EQ Vault Members are core team members and weild equal rights for it's operations and in decision making. Member's may inititate the addition and removal instance. It needs appraval of more than 50% members for its success.
                </p>
            </div>
            <div className='form w-1/2 lg:width-full welcome-card rounded-lg p-6'>
                <Form
                    name="basic"
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    initialValues={{
                        member_name: "",
                        member_wallet_address: "",
                        member_email: "",
                    }}
                    autoComplete="off"
                    layout='vertical'
                    form={form}
                >
                    <Form.Item
                        label="Wallet"
                        name="member_wallet_address"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your wallet address!',
                            },
                        ]}
                    >
                        <Select onChange={(e) => handleChange(e)}>
                            {members && members?.length > 0 && members.map((member) => {
                                { console.log(member) }
                                return <Option key={member} value={member}>{member}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    {/* <Form.Item
                        label="Full Name"
                        name="member_name"
                        rules={[
                            {
                                // required: true,
                                message: 'Please input your username!',
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item>
                    <Form.Item
                        label="Email Address"
                        name="member_email"
                        rules={[
                            {
                                type: 'email',
                                // required: true,
                                message: 'Please input your email!',
                            },
                        ]}
                    >
                        <Input disabled={true} />
                    </Form.Item> */}


                    <Form.Item >
                        <p className='text-center mt-3'>
                            New Member will be removed once all the existing members approve the removal instance witin 7 days
                        </p>
                    </Form.Item>

                    <Form.Item className=''>
                        <div className='text-center'>
                            <Button type="primary" className='flex gap-1 mx-auto grad-btn border-0 ' htmlType="submit">
                                Remove <svg xmlns="http://www.w3.org/2000/svg" className="self-center icon icon-tabler icon-tabler-arrow-narrow-right" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l14 0"></path>
                                    <path d="M15 16l4 -4"></path>
                                    <path d="M15 8l4 4"></path>
                                </svg>
                            </Button>
                        </div>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        org: state.org,
    };
};

export default connect(mapStateToProps)(RemoveMembers);