import { Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ConfirmModal from '../../../../components/modals/ConfirmModal';
import store from "../../../../redux/store";
import { updateSpinner } from "../../../../redux/actions";
import { getAccount, getWeb3 } from "../../../../helpers/currentWalletHelper";
import multiSigv2Abi from "./../../../../Config/abis/EquinoxMain.json";
import { CommonModal } from "../../../../components/modals";
import { getMemberVotedList, getOrg } from "../../../../services/dashboard";
import DetailModal from '../../../../components/DetailModal';
import { getMultiSigWallet, getRemoveMembers } from '../../../../helpers/getterFunctions';
import { getContractInstance } from '../../../../components/Accountmodal';
import contracts from '../../../../Config/contracts';
import deployerAbi from "./../../../../Config/abis/multisigDeployer.json"
import { getAddress } from '../../../../helpers/addressHelper';

function RemoveMemberRequest(props) {
  const { org, auth } = props;
  const [signers, setSigners] = useState([]);
  const [currentValues, setCurrentValues] = useState({});
  const [approveModal, setApproveModal] = useState(false);
  const [dissApproveModal, setDissApproveModal] = useState(false);
  const [finalizeModal, setFinalizeModal] = useState(false);
  const [voters, setVoter] = useState([]);
  const [view, setView] = useState(false);
  const [votingLoading, setVotingLoading] = useState(false);
  const [message, setMessage] = useState("Member has been removed");
  const [open, setOpen] = useState(false);
  const [removeMemberList, setRemoveMemberList] = useState([]);
  const [approveRemoveMemberModal, setApproveRemoveMemberModal] = useState(false);
  const [dissApproveRemoveMemberModal, setDissApproveRemoveMemberModal] = useState(false);
  const [finalizeRemoveMemberModal, setFinalizeRemoveMemberModal] = useState(false);
  useEffect(() => {
    if (org?.org, auth) {
      getSigners();
      getMemberVotedList(auth.id);
      getRemoveMembersList(org);
    }
  }, [org, auth]);

  useEffect(() => {
    //REMOVE MEMBER DISSAPPROVED CHECK
    const checkIfDissapproved = async () => {
      if (org && org.members.length > 0) {
        let account = await getAccount();
        let multiSigAddr = getMultiSigWallet();
        const contract = await getContractInstance(
          multiSigAddr,
          multiSigv2Abi.abi
        );
        console.log("REMOVE MEMBER");
        removeMemberList.length > 0 &&
          removeMemberList.forEach(async (element) => {
            console.log(element);

            const {
              data: { response: indexListOftransferProposal },
            } = await axios.post(
              `${process.env.REACT_APP_API_URL}/get_index_list`,
              {
                org_id: org?.org?.id,
                type: "remove_member",
              }
            );
            console.log(indexListOftransferProposal);

            const propIndex =
              indexListOftransferProposal?.filter(
                (val) => Number(val.data) === element.id
              )[0].index_number - 1;
            console.log(propIndex);
            if (contract) {
              const resFromBlock = await contract.methods
                .isFinalRemoveMemberProposal(propIndex)
                .call();
              console.log(propIndex, resFromBlock);
              if (resFromBlock === true) {
                // if (false) {
                axios
                  .get(
                    `${process.env.REACT_APP_API_URL}/approve_remove_member/${element.wallet_address}/-1`
                  )
                  .then((res) => {
                    console.log(res.data.status);
                    if (res.data.status === "error") {
                      console.log(res.data.status);
                    }

                    if (auth && auth.org_id) getOrg(auth.org_id);
                  });
              }
            }
          });
      }
    };
    checkIfDissapproved();
  }, [auth, org, removeMemberList]);

  const getRemoveMembersList = async (org) => {
    try {
      console.log(org);
      const response = (await getRemoveMembers())?.pendingRequests
      console.log(response);
      setRemoveMemberList(response);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRemoveMembersList()
  }, [])

  const getVotingList = async (pro, type) => {
    if (org && org?.members?.length > 0) {
      setVotingLoading(true);

      setVoter([]);
      org.members
        .filter((member) => member.is_active === 1)
        .forEach(async (element) => {
          let objectOfMember = {};
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/get_vote_list_by_type/${type}/${element.id}`
            )
            .then(({ data }) => {
              console.log(data, pro);
              if (data.status === "success") {
                const matchedVales = data.response.filter(
                  (item) => item.proposal_id === pro?.id
                );
                console.log("Matched", matchedVales);
                objectOfMember = element;
                if (matchedVales.length > 0) {
                  objectOfMember.voted = "Yes";
                } else {
                  objectOfMember.voted = "No";
                }
              }
              if (data.status === "error") {
                objectOfMember = element;
                objectOfMember.voted = "No";
              }

              setVoter((prev) => [...prev, objectOfMember]);
              setVotingLoading(false);
            })
            .catch((error) => {
              setVotingLoading(false);
              console.log(error);
              // setLoading(false);
            });
        });
      console.log("calling");
      // setLoading(false);
      setView(true);
    }
  };
  //ADD MEMBER _FUNCTIONS
  // const approve = async (id, to) => {
  //   try {
  //     store.dispatch(updateSpinner(true));
  //     let account = await getAccount();
  //     let multiSigAddr = getMultiSigWallet();
  //     const contract = await getContractInstance(
  //       multiSigAddr,
  //       multiSigv2Abi.abi
  //     );

  //     // const propIndex = Number(org?.org?.add_member_index) - 1;
  //     console.log("INdex", id);
  //     console.log("approving", id, auth?.id);
  //     const isNotAbleTosign = await contract.methods
  //       .hasAddMemberProposolSigned(id, account)
  //       .call();

  //     console.log(isNotAbleTosign);
  //     const isSigned = await contract.methods
  //       .isAddMemberProposalDisapproved(id, account)
  //       .call();

  //     if (isSigned) {
  //       store.dispatch(updateSpinner(false));
  //       alert("Member is Disapproved");
  //     } else {
  //       if (!isNotAbleTosign) {
  //         store.dispatch(updateSpinner(true));

  //         await contract.methods
  //           .signAddMemberProposal(id)
  //           .send({ from: account })
  //           .on("error", (error) => {
  //             console.log(error);
  //             store.dispatch(updateSpinner(false));
  //           })
  //           .then(async (result) => {
  //             store.dispatch(updateSpinner(false));
  //             setMessage("Your Vote has been registered");
  //             setOpen(true);
  //             setTimeout(() => {
  //               setOpen(false);
  //             }, 5000);
  //             if (auth && auth.org_id) getOrg(auth.org_id);
  //           });
  //       } else {
  //         alert("Your vote has already been registered.");
  //       }
  //     }
  //   }
  //   catch (err) {
  //     console.log("err", err)
  //   }
  // };

  // const finalizeAddMember = async (id, memberAddress) => {
  //   store.dispatch(updateSpinner(true));
  //   let account = await getAccount();
  //   let multiSigAddr = getMultiSigWallet();
  //   const contract = await getContractInstance(
  //     multiSigAddr,
  //     multiSigv2Abi.abi
  //   );
  //   const {
  //     data: { response: indexListOftransferProposal },
  //   } = await axios.post(`${process.env.REACT_APP_API_URL}/get_index_list`, {
  //     org_id: org?.org?.id,
  //     type: "add_member",
  //   });
  //   console.log(indexListOftransferProposal);

  //   const propIndex =
  //     indexListOftransferProposal?.filter(
  //       (val) => val.data.toLowerCase() === memberAddress.toLowerCase()
  //     )[0].index_number - 1;
  //   console.log(propIndex);
  //   console.log("Finalizing", propIndex);
  //   const isAbleTosign = await contract.methods
  //     .addMemberProposalRequirementMet(propIndex)
  //     .call();
  //   const isSigned = await contract.methods
  //     .isAddMemberProposalDisapproved(propIndex, account)
  //     .call();
  //   console.log(isAbleTosign, memberAddress);
  //   if (isSigned) {
  //     store.dispatch(updateSpinner(false));
  //     alert("Member is Disapproved");
  //   } else {
  //     if (isAbleTosign) {
  //       store.dispatch(updateSpinner(true));

  //       await contract.methods
  //         .finalizeAddMemberProposal(propIndex)
  //         .send({ from: account })
  //         .on("error", (error) => {
  //           console.log(error);
  //           store.dispatch(updateSpinner(false));
  //         })
  //         .then((result) => {
  //           const formData = new FormData();
  //           formData.append("member_id", id);
  //           formData.append("voter_id", auth?.id);
  //           formData.append("vote", 1);
  //           axios
  //             .get(
  //               `${process.env.REACT_APP_API_URL}/approve_member/${memberAddress}/1`
  //             )
  //             .then((res) => {
  //               store.dispatch(updateSpinner(false));
  //               setMessage("Member is Finalized successfully");
  //               setOpen(true);
  //               setTimeout(() => {
  //                 setOpen(false);
  //               }, 5000);
  //               if (auth && auth.org_id) getOrg(auth.org_id);
  //             });
  //         });
  //     } else {
  //       alert("This Proposal hasn't reached minimum votes required to Finalize.");
  //     }
  //   }
  // };
  // console.log(auth.org_id);

  // const disApprove = async (id, to) => {
  //   try {
  //     store.dispatch(updateSpinner(true));
  //     let account = await getAccount();
  //     let multiSigAddr = getMultiSigWallet();
  //     const contract = await getContractInstance(
  //       multiSigAddr,
  //       multiSigv2Abi.abi
  //     );

  //     const isSigned = await contract.methods
  //       .isAddMemberProposalDisapproved(id, account)
  //       .call();
  //     const isApproved = await contract.methods
  //       .hasAddMemberProposolSigned(id, account)
  //       .call();
  //     console.log(isSigned);
  //     if (isApproved) {
  //       store.dispatch(updateSpinner(false));
  //       alert("You already voted");
  //     } else {
  //       if (!isSigned) {
  //         store.dispatch(updateSpinner(true));

  //         await contract.methods
  //           .disapproveAddMemberProposal(id)
  //           .send({ from: account })
  //           .on("error", (error) => {
  //             console.log(error);
  //             store.dispatch(updateSpinner(false));
  //           })
  //           .then(async (res) => {

  //             store.dispatch(updateSpinner(false));
  //             setMessage("Your Vote has been registered");
  //             setOpen(true);
  //             setTimeout(() => {
  //               setOpen(false);
  //             }, 5000);

  //             if (auth && auth.org_id) getOrg(auth.org_id);
  //           });
  //       } else {
  //         alert("Already Dissapproved!");
  //       }
  //     }
  //   }
  //   catch (err) {
  //     console.log("err", err)
  //   }
  // };
  //REMOVE MEMBER _FUNCTIONS
  const approveRemoveMember = async (proposal) => {
    try {
      let id = proposal?.reqId
      console.log("APPROVE REMOVE", proposal);
      store.dispatch(updateSpinner(true));
      // return;
      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );

      let deployer = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);

      console.log("deployer", deployer)
      // const propIndex = Number(org?.org?.remove_member_index) - 1;
      const isRemoveMemberSigned = await contract.methods
        .removeMemberProposalSigned(id, account)
        .call();
      console.log(isRemoveMemberSigned);
      const isSigned = await contract.methods
        .isRemoveMemberProposalDisapproved(id, account)
        .call();
      console.log(isSigned);
      if (isSigned) {
        alert("Member is Disapproved");
        store.dispatch(updateSpinner(false));
      } else {
        if (!isRemoveMemberSigned) {
          store.dispatch(updateSpinner(true));
          await deployer.methods
            .signRemoveMemberProposal(id, multiSigAddr)
            .send({ from: account })
            .on("error", (error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            })
            .then(async (result) => {

              store.dispatch(updateSpinner(false));

              setMessage("Your Vote has been registered");
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 5000);
              console.log(result);
            });
        } else {
          alert("Your vote has already been registered.");
          store.dispatch(updateSpinner(false));

        }
      }
    }
    catch (err) {
      console.log("err", err)
      store.dispatch(updateSpinner(false));

    }
  };

  const disapproveRemoveMember = async (proposal) => {
    try {
      let id = proposal?.reqId
      store.dispatch(updateSpinner(true));
      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      let contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );

      // const propIndex = Number(org?.org?.remove_member_index) - 1;
      const isSigned = await contract.methods
        .isRemoveMemberProposalDisapproved(id, account)
        .call();
      const isApproved = await contract.methods
        .hasRemoveMemberProposolSigned(id, account)
        .call();
      if (isApproved) {
        alert("Your vote already registered");
        store.dispatch(updateSpinner(false));
      } else {
        console.log(isSigned);
        if (!isSigned) {
          store.dispatch(updateSpinner(true));
          contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);

          await contract.methods
            .disapproveRemoveMemberProposal(id)
            .send({ from: account })
            .on("error", (error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            })
            .then(async (result) => {

              store.dispatch(updateSpinner(false));
              setMessage("Your Vote has been registered");
              setOpen(true);
              setTimeout(() => {
                setOpen(false);
              }, 5000);
            });
        } else {
          alert("Already Dissapproved!");
          store.dispatch(updateSpinner(false));

        }
      }
    }
    catch (err) {
      console.log("err", err)
      store.dispatch(updateSpinner(false));

    }
  };

  const finalizeRemoveMember = async (to) => {
    try {
      let id = to?.reqId
      store.dispatch(updateSpinner(true));
      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      let contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );

      const isRemoveMemberAllSigned = await contract.methods
        .hasRemoveMemberProposolSigned(id, account)
        .call();
      console.log(isRemoveMemberAllSigned);
      const isSigned = await contract.methods
        .isRemoveMemberProposalDisapproved(id, account)
        .call();
      console.log(isSigned);

      const isFullySigned = await contract.methods
        .removeProposalRequirementMet(id)
        .call();
      console.log(isFullySigned);
      if (isFullySigned) {
        if (isSigned) {
          store.dispatch(updateSpinner(false));
          alert("Member is Disapproved");
          store.dispatch(updateSpinner(false));

        } else {
          if (isRemoveMemberAllSigned) {
            contract = await getContractInstance(getAddress(contracts.multisigDeployer), deployerAbi);

            await contract.methods
              .removeWalletMember(id, multiSigAddr)
              .send({ from: account })
              .on("error", (error) => {
                console.log(error);
                store.dispatch(updateSpinner(false));
              })
              .then((result) => {
                console.log(result);
                const formData = new FormData();
                formData.append("wallet_address", to.member);

                store.dispatch(updateSpinner(false));
                setMessage("Member has been removed");
                setOpen(true);

                setTimeout(() => {
                  setOpen(false);
                }, 5000);
                getSigners();
              });

          } else {
            alert("This Proposal hasn't reached minimum votes required to Finalize.");
            store.dispatch(updateSpinner(false));
          }
        }
      } else {
        alert("This Proposal hasn't reached minimum votes required to Finalize.");
        store.dispatch(updateSpinner(false));
      }
    } catch (err) {
      console.log("err", err)
    }
  };
  const getSigners = async () => {
    let account = await getAccount();
    let multiSigAddr = getMultiSigWallet();
    const contract = await getContractInstance(
      multiSigAddr,
      multiSigv2Abi.abi
    );
    const returnvalue = await contract.methods.listRemoveMembers().call();
    console.log(returnvalue);
    setSigners(returnvalue);
  };
  // const approveHandler = (values) => {
  //   console.log(values);
  //   setApproveModal(false);
  //   approve(values.reqId, values.member);
  // };

  // const dissApproveHandler = (values) => {
  //   console.log(values);
  //   setDissApproveModal(false);
  //   disApprove(values.reqId, values.member);
  // };

  // const finalizeHandler = (values) => {
  //   console.log(values);
  //   setFinalizeModal(false);
  //   finalizeAddMember(values.reqId, values.wallet_address);
  // };
  const removeApproveHandler = (values) => {
    console.log(values);
    setApproveRemoveMemberModal(false);
    approveRemoveMember(values);
  };
  const removeDissApproveHandler = (values) => {
    console.log(values);
    setDissApproveRemoveMemberModal(false);
    disapproveRemoveMember(values);
  };
  const removeFinalizeHandler = (values) => {
    console.log(values);
    setFinalizeRemoveMemberModal(false);
    finalizeRemoveMember(values);
  };
  return (
    <div>
      {removeMemberList.length > 0 &&
        removeMemberList.map((pro, index) => {
          return (
            <div className='welcome-card rounded-lg p-6 mb-6 text-black' key={pro.id}>
              <div className='flex flex-wrap gap-6 justify-between'>
                <div>
                  Wallet Address: <b>{pro.member}</b>
                </div>
                <div>
                  {!pro.finalized && (
                    <span
                      onClick={() => {
                        setCurrentValues(pro);
                        setApproveRemoveMemberModal(true);
                      }}
                      className="approve"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M5 12l5 5l10 -10"></path>
                      </svg>
                    </span>
                  )}
                </div>
                <div>
                  <span
                    onClick={() => {
                      setCurrentValues(pro);
                      setDissApproveRemoveMemberModal(true);
                    }}
                    className="approve"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M18 6l-12 12"></path>
                      <path d="M6 6l12 12"></path>
                    </svg>
                  </span>
                </div>
                <div className='flex gap-3'>
                  {!pro.finalized && (<Button type='primary' className='grad-btn border-0' onClick={() => {
                    setCurrentValues(pro);
                    setFinalizeRemoveMemberModal(true);
                  }}>Finalize</Button>)}
                  {/* <Button type='primary' className='grad-btn border-0' onClick={() => {
                    setCurrentValues(pro);
                    getVotingList(pro, 3);
                    // setFinalizeModal(true);
                  }}>View</Button> */}
                </div>
              </div>
            </div>
          )
        })
      }
      {/* <ConfirmModal
        title="Are you sure you want to Approve!"
        open={approveModal}
        setOpen={setApproveModal}
        handler={approveHandler}
        currentValues={currentValues}
      /> */}
      {/* <ConfirmModal
        title="Are you sure you want to Dissapprove!"
        open={dissApproveModal}
        setOpen={setDissApproveModal}
        handler={dissApproveHandler}
        currentValues={currentValues}
      /> */}
      {/* <ConfirmModal
        title="Are you sure you want to Finalize!"
        open={finalizeModal}
        setOpen={setFinalizeModal}
        handler={finalizeHandler}
        currentValues={currentValues}
      /> */}
      <CommonModal
        message={message}
        note=""
        note_bracket=""
        open={open}
        setOpen={setOpen}
      />
      <ConfirmModal
        title="Are you sure you want to Approve!"
        open={approveRemoveMemberModal}
        setOpen={setApproveRemoveMemberModal}
        handler={removeApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Dissapprove!"
        open={dissApproveRemoveMemberModal}
        setOpen={setDissApproveRemoveMemberModal}
        handler={removeDissApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Finalize!"
        open={finalizeRemoveMemberModal}
        setOpen={setFinalizeRemoveMemberModal}
        handler={removeFinalizeHandler}
        currentValues={currentValues}
      />
      <DetailModal
        open={view}
        setOpen={setView}
        handleOpen={() => setView(true)}
        handleClose={() => setView(false)}
        voters={voters}
        votingLoading={votingLoading}
      />
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    org: state.org,
    auth: state.auth
  };
};

export default connect(mapStateToProps)(RemoveMemberRequest);