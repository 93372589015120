import React, { useEffect,useState } from 'react';
import TeamMembersCard from '../../../../components/TeamMembersCard';
import { connect } from 'react-redux';
import { getTeamMembers } from '../../../../helpers/getterFunctions';

function TeamMembers(props) {
    const { org } = props;
    const [teamMembers, setTeamMembers] = useState([])
    useEffect(() => {
        const fetch = async () => {
            let members = await getTeamMembers()
            setTeamMembers(members)
        }
        fetch()
    },[])
    console.log(org)
    return (
        <div>
            <div className="grid grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 max-md:grid-cols-2 gap-6 max-lg:text-center">
                {teamMembers && teamMembers?.length>0 && teamMembers.map((member) => (
                            <TeamMembersCard
                                membername={member} key={member.id}
                            />
                        ))}
            </div>
        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        org: state.org,
    };
};

export default connect(mapStateToProps)(TeamMembers);