import { Breadcrumb, Button, Form, Input, message, Modal, Select, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import CompleteRequest from './asset-tab-content/CompleteRequest';
import Overview from './asset-tab-content/Overview';
import SubscriptionCompleteRequest from './asset-tab-content/SubscriptionCompleteRequest';
import SubscriptionPendingRequest from './asset-tab-content/SubscriptionPendingRequest';
import PendingRequest from './asset-tab-content/PendingRequest';
import { connect } from 'react-redux';
import { getAccount, getWeb3 } from "../../../helpers/currentWalletHelper";
import minABI from "../../../Config/abis/BalanceOf.json";
import MultiSig from "../../../Config/abis/EquinoxMain.json";
import store from "../../../redux/store";
import { updateSpinner } from "../../../redux/actions";
import { toFixed } from "../../../helpers/numberFormatter";
import axios from "axios";
import { getOrg } from "../../../services/dashboard";
import { GasError } from "../../../components/modals";
import Spinner from "../../../components/Spinner/Spinner";

// TOKEN CONTRACTS START
import BNBContract from "../../../Config/abis2/BNB.json";

import BUSDContract from "../../../Config/abis2/BUSD.json";

import ETHContract from "../../../Config/abis2/ETH.json";

import USDTContract from "../../../Config/abis2/USDT.json";
import {
    // CREATE_ASSETS_PROPOSAL,
    CREATE_PROPOSAL_PAYABLE_VALUE,
} from "../../../utils";
import Web3 from 'web3';
import { getContractInstance } from '../../../components/Accountmodal';
import { getAddress } from '../../../helpers/addressHelper';
import contracts from '../../../Config/contracts';
import { getAddedTokens, getMultiSigWallet, getTokenSymbol } from '../../../helpers/getterFunctions';
import FailedRequest from './asset-tab-content/FailedRequest';
//TOKEN CONTRACTS END

function Assets(props) {
    const [searchParams] = useSearchParams();
    const createReq = searchParams.get('createReq');
    const onFinish = async (values) => {
        console.log('Success:', values);
        await new Promise((r) => setTimeout(r, 500));
        deploy(values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    useEffect(() => {
        console.log(createReq, '----r----')
        setModal2Open(createReq)
    }, [createReq])

    const { org, auth, history } = props;
    const [form] = Form.useForm();
    const { Option } = Select;
    const [balance, setBalance] = useState(0);
    const [spinner, setSpinner] = useState(false);
    const [gasError, setGasError] = useState(false);
    const [currentTokenBalance, setCurrentTokenBalance] = useState(0);
    const [nameLoading, setNameLoading] = useState(false)
    const [addTokenField, setAddTokenFields] = useState({})
    const [modal2Open, setModal2Open] = useState(false);
    const [addTokenModalOpen, setAddTokenModalOpen] = useState(false);
    const [addedTokens, setAddedTokens] = useState()
    const [isTxGoingOn, setIsTxGoingOn] = useState(false)

    const options = [
        {
            id: "gtoken",
            name: `${org?.project[0]?.token_name
                ? org?.project[0]?.token_name
                : "Gtoken (Not Created yet)"
                }`,
            address: org?.project[0]?.gtoken_address,
        },
        {
            id: "BNB",
            name: `BNB`,
            address: getAddress(contracts.bnb),
        },
        {
            id: "BUSD",
            name: `BUSD`,
            address: getAddress(contracts.busd),
        },
        {
            id: "ETH",
            name: "ETH",
            address: getAddress(contracts.eth),
        },
        {
            id: "USDT",
            name: `USDT`,
            address: getAddress(contracts.usdt),
        },
    ];
    const gTokenAddr = org?.project[0]?.gtoken_address;
    const [reload, setReload] = useState(false)
    useEffect(() => {
        const fetch = async () => {
            let added = await getAddedTokens()
            setAddedTokens(added)
        }
        fetch()
    }, [reload])

    const setTokenBalance = async (abi, address, value) => {
        const web3 = await getWeb3();
        console.log("abi addr", abi, address)
        // let accounts = await web3.eth.getAccounts();
        // let account = accounts[0];
        const multiSigAddr = org?.org?.multisig_address;
        const contract = await getContractInstance(address, abi);
        console.log("token contract", contract)
        if (contract) {
            const _balance = await contract.methods.balanceOf(getMultiSigWallet()).call();
            const _acctualBal = web3.utils.fromWei(`${_balance}`, "ether");
            console.log("CURRENT TOKEN BALANCE", _acctualBal);
            setCurrentTokenBalance(+_acctualBal);

            // console.log(_balance);
        }
    };

    const handleTokenAddrChange = async (e) => {
        let web3 = await getWeb3()
        console.log("value", e, web3)
        // setIsAssetsFieldDisabled(true)
        if (web3.utils.isAddress(e)) {
            console.log("valid")
            form.setFieldValue("token_name", "Loading..")
            let symbol = await getTokenSymbol(e)
            console.log("Symbol", symbol)
            form.setFieldValue("token_name", symbol)
            setAddTokenFields({ tokenAddress: e, symbol: symbol })
            await setTokenBalance(USDTContract?.abi, e, "")
        }
        // if (!e) {
        //     setIsAssetsFieldDisabled(false)
        // }
    }

    const handleChange = async (e) => {
        console.log("EEEEE", e)
        form.setFieldValue("token_name", "Loading..")
        let symbol = await getTokenSymbol(e)
        form.setFieldValue("asset", e)
        setAddTokenFields({ tokenAddress: e, symbol: symbol })
        await setTokenBalance(USDTContract?.abi, e, "")
        // if (value === "gtoken") {
        //     if (!gTokenAddr) {
        //         alert("Please select a valid token");
        //         return;
        //     }
        //     form.setFieldValue("asset", value);
        //     await setTokenBalance(minABI.abi, gTokenAddr, value);
        //     // console.log()
        // } else if (value === "BNB") {
        //     form.setFieldValue("asset", value);
        //     await setTokenBalance(BNBContract.abi, getAddress(contracts.bnb), value);
        // } else if (value === "BUSD") {
        //     form.setFieldValue("asset", value);
        //     await setTokenBalance(BUSDContract.abi, getAddress(contracts.busd), value);
        // } else if (value === "ETH") {
        //     form.setFieldValue("asset", value);
        //     await setTokenBalance(ETHContract.abi, getAddress(contracts.eth), value);
        // } else if (value === "USDT") {
        //     form.setFieldValue("asset", value);
        //     await setTokenBalance(USDTContract.abi, getAddress(contracts.usdt), value);
        // }

        // let res;
        // if (e.target.value === "BNB") {
        //   res = await getTokenBalance(
        //     e.target.value.toLowerCase(),
        //     org?.org?.multisig_address
        //   );
        // }
        // console.log(res);
        // setBalance(res);
    };

    const limitMaxvalue = (e, values) => {
        var currentValue = String.fromCharCode(e.which);
        const val = e.target.value + currentValue;
        console.log(val);
        // if (balance <= parseFloat(val)) {
        //   e.preventDefault();
        // }
    };

    const deploy = async (values) => {
        // setSpinner(true);

        try {
            setIsTxGoingOn(true)
            const web3 = await getWeb3();
            let account = await getAccount();
            const multiSigAddr = getMultiSigWallet();
            const gTokenAddr = org?.project[0]?.gtoken_address;
            const contract = new web3.eth.Contract(MultiSig.abi, multiSigAddr);
            const selectedToken = values?.asset;
            console.log("Token ", selectedToken)
            console.log(values.asset);
            console.log(values);
            store.dispatch(updateSpinner(true));
            console.log("current token bal", currentTokenBalance, values?.quantity)
            // return;
            setSpinner(true);
            if (currentTokenBalance > values.quantity) {
                await contract.methods
                    .submitTransferProposal(
                        toFixed(values.quantity * 1000000000 * 1000000000).toLocaleString(
                            "fullwide",
                            {
                                useGrouping: false,
                            }
                        ),
                        selectedToken,
                        values.receiver_wallet
                    )
                    .send({
                        from: account,
                        value: web3.utils.toWei(CREATE_PROPOSAL_PAYABLE_VALUE, "ether"),
                    })
                    .on("error", (error) => {
                        console.log(error);
                        setGasError(true);
                        setIsTxGoingOn(false)
                        store.dispatch(updateSpinner(false));
                    })
                    .then((result) => {
                        console.log(result);

                        store.dispatch(updateSpinner(false));
                        setSpinner(false);
                        message.success("Proposal Submitted Successfully");
                        setIsTxGoingOn(false)
                        window.location.reload()
                    });
            } else {
                store.dispatch(updateSpinner(false));
                setSpinner(false);
                setIsTxGoingOn(false)
                alert("You don't have sufficient Balance");
            }
        }
        catch (Err) {
            console.log("Err", Err)
            setIsTxGoingOn(false)
            store.dispatch(updateSpinner(false));
            setSpinner(false)
        }
    };


    const onAddTokenFinish = async () => {
        try {
            store.dispatch(updateSpinner(true));
            setSpinner(true);
            let tokenAddress = addTokenField?.tokenAddress
            let symbol = addTokenField?.symbol

            let tokensArray = await getAddedTokens()
            let isTokenAreadyExist = false
            tokensArray.filter((t, i) => {
                if (t?.tokenAddress?.toLowerCase() == tokenAddress?.toLowerCase()) {
                    isTokenAreadyExist = true
                    setSpinner(false)
                    // alert("Token Already Exists")
                    // store.dispatch(updateSpinner(false));
                    return
                }
            })
            if (isTokenAreadyExist) {
                // store.dispatch(updateSpinner(false));
                setSpinner(false)

                return
            }
            setAddTokenModalOpen(false)
            tokensArray.push({ "tokenAddress": tokenAddress, "symbol": symbol })
            localStorage.setItem(`tokens-${localStorage.getItem("selected_chain")}-${localStorage.getItem("eqx_connected_account")}`, JSON.stringify(tokensArray))
            console.log("tokensArray", tokensArray)
            window.location.reload()
            // store.dispatch(updateSpinner(false));
        }
        catch (err) {
            console.log("Err", err)
            setSpinner(false)
            // store.dispatch(updateSpinner(false));
        }
    }

    const setFieldValue = (key, value) => {
        form.setFieldValue(key, value)
    }

    const items = [
        {
            key: '1',
            label: `Overview`,
            children: <Overview setModal2Open={setModal2Open}
                //  setIsAssetsFieldDisabled={setIsAssetsFieldDisabled}
                setFieldValue={setFieldValue}
                handleTokenAddrChange={handleTokenAddrChange} />,
        },
        {
            key: '2',
            label: `Subscription Completed Request`,
            children: <SubscriptionCompleteRequest />,
        },
        {
            key: '3',
            label: `Subscription Pending Request`,
            children: <SubscriptionPendingRequest />,
        },
        {
            key: '4',
            label: `Completed Request`,
            children: <CompleteRequest />,
        },
        {
            key: '5',
            label: `Pending Request`,
            children: <PendingRequest />,
        },
        {
            key: '6',
            label: `Failed Request`,
            children: <FailedRequest />,

        },
    ];


    return (
        <div>
            <div className=''>
                <Breadcrumb>
                    <Breadcrumb.Item>
                        <Link to='/dashboard/home'>Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item className='text-pink-500 font-bold'>Assets</Breadcrumb.Item>
                </Breadcrumb>
            </div>
            <div className='mb-4'>
                <Tabs items={items} />
            </div>
            <div className='flex gap-3'>
                <div>
                    <Button onClick={() => setModal2Open(true)} type='primary' className='grad-btn border-0'>Create New Request</Button>
                    <Button onClick={() => setAddTokenModalOpen(true)} type='primary' className='grad-btn border-0'>Add New Token</Button>

                </div>
                <div>
                </div>
            </div>

            <Modal
                centered
                open={modal2Open}
                footer={null}
                onOk={() => setModal2Open(false)}
                onCancel={() => setModal2Open(false)}

            >
                <div className='mt-6'>
                    <div className='text-center'>
                        <div className='text-xl font-bold mb-3'>
                            Create Request
                        </div>
                        <p className='mb-6'>
                            Create request to initiating an instance for sending crypto assets to receiver wallet. it must be approved by all members within 7days of initiation for sucessfull transaction
                        </p>
                    </div>
                    <div>
                        <Form
                            name="basic"
                            layout='vertical'
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            initialValues={{
                                receiver_wallet: "",
                                asset: "",
                                quantity: 0,
                                description: "",
                            }}
                            autoComplete="off"
                            form={form}
                        >
                            <Form.Item
                                label="Receiver wallet"
                                name="receiver_wallet"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                                label="Token Address"
                                name="token_address"
                                onChange={(e) => {
                                    handleTokenAddrChange(e.target.value)
                                }}

                                rules={[
                                    {
                                        message: 'Required'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Token Name"
                                name="token_name"

                                rules={[
                                    {
                                        message: 'Required'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            OR */}
                            <Form.Item
                                label="Asset"
                                name="asset"
                                rules={[
                                    {
                                        // required: true,
                                    },
                                ]}
                            >
                                <Select
                                    onChange={(e) => handleChange(e)}
                                // disabled={isAssetsFieldDisabled}
                                >
                                    {addedTokens && addedTokens?.length > 0 && addedTokens.map((o, i) => {
                                        return <Option key={i} value={o.tokenAddress}>{o.symbol}</Option>
                                    })}
                                </Select>
                            </Form.Item>
                            <Form.Item
                                label="Quantity"
                                name="quantity"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            {/* <Form.Item
                                label="Description"
                                name="description"
                                rules={[
                                    {
                                        required: true,
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item> */}
                            <Form.Item className='text-center'>
                                <Button disabled={isTxGoingOn} type="primary" htmlType="submit" className='grad-btn border-0'>
                                    {isTxGoingOn ? "Processing..." : "Initiate"}
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            <Modal
                centered
                open={addTokenModalOpen}
                footer={null}
                onOk={() => setAddTokenModalOpen(false)}
                onCancel={() => setAddTokenModalOpen(false)}
            >
                <div className='mt-6'>

                    <div className='text-center'>
                        <div className='text-xl font-bold mb-3'>
                            Add Token
                        </div>

                    </div>
                    <div>
                        <Form
                            name="basic"
                            layout='vertical'
                            onFinish={async () => { await onAddTokenFinish() }}
                            onFinishFailed={onFinishFailed}
                            initialValues={{
                                receiver_wallet: "",
                                asset: "",
                                quantity: 0,
                                description: "",
                            }}
                            autoComplete="off"
                            form={form}
                        >

                            <Form.Item
                                label="Token Address"
                                name="token_address"
                                onChange={(e) => {
                                    handleTokenAddrChange(e.target.value)
                                }}

                                rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item
                                label="Token Name"
                                name="token_name"

                                rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item className='text-center'>
                                <Button type="primary" htmlType="submit" className='grad-btn border-0'>
                                    Add Token
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </Modal>

            {spinner && <Spinner />}
            <GasError open={gasError} setOpen={setGasError} />

        </div>
    );
}
const mapStateToProps = (state) => {
    return {
        org: state.org,
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Assets);