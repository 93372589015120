import React, { useState, useEffect, useCallback } from "react";
import Web3 from "web3";
import { connect } from "react-redux";
import { accountUpdate } from "../redux/actions";
import Web3Modal, { local } from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import detectEthereumProvider from "@metamask/detect-provider";
// import GeneralModal from "./wrongNetworkModal";
import { NetworkError } from "./modals";
import { Web3Auth } from "@web3auth/modal";
import RPC from "./../web3RPC"
import { getWeb3 } from "../helpers/currentWalletHelper";
import { getMultiSigWallet, getSelectedChainData } from "../helpers/getterFunctions";
import EventEmitter from "../events";
// const clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"

export const web3AuthInstance = async () => {
  try {
    const web3auth = new Web3Auth({
      clientId: getSelectedChainData()?.clientId,
      web3AuthNetwork: getSelectedChainData()?.network, // mainnet, aqua,  cyan or testnet
      chainConfig: {
        chainNamespace: "eip155",
        chainId: getSelectedChainData()?.chainId,
        rpcTarget: getSelectedChainData()?.rpc, // This is the public RPC we have added, please pass on your own endpoint while creating an app
      },
    });


    await web3auth.initModal();
    console.log("web3auth.provider", web3auth.provider)
    if (web3auth.provider) {
      return web3auth.provider
    };
  }
  catch (Err) {
    console.log("Err", Err)
  }
}

export const getContractInstance = async (address, abi) => {
  try {
    let web3 = await getWeb3()
    let contract = new web3.eth.Contract(abi, address)
    return contract
  }
  catch (Err) {
    console.log("Err", Err)
  }
}

const AccountModal = (props) => {
  const [currentAccount, setCurrentAccount] = useState(null);
  const [wrongNetwork, setWrongNetwork] = useState(false);
  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [selectedChain, setSelectedChain] = useState("97")
  let eventEmitter = new EventEmitter()
  eventEmitter.on("wrongNetwork", () => {
    setWrongNetwork(true)
  })
  useEffect(() => {
    if (!localStorage.getItem("selected_chain")) {
      localStorage.setItem("selected_chain", "97")
    }
    setSelectedChain(localStorage.getItem("selected_chain") ?? "97")
  }, [])
  useEffect(() => {
    const init = async () => {
      try {
        const web3auth = new Web3Auth({
          clientId: getSelectedChainData()?.clientId,
          web3AuthNetwork: getSelectedChainData()?.network, // mainnet, aqua,  cyan or testnet
          chainConfig: {
            chainNamespace: "eip155",
            chainId: getSelectedChainData()?.chainId,
            rpcTarget: getSelectedChainData()?.rpc, // This is the public RPC we have added, please pass on your own endpoint while creating an app
          },
        });
        setWeb3auth(web3auth);
        await web3auth.initModal();
        if (web3auth.provider) {
          setProvider(web3auth.provider);
        };
      } catch (error) {
        console.error(error);
      }
    };
    init();
  }, []);

  const login = async () => {
    try {
      if (!web3auth) {
        uiConsole("web3auth not initialized yet");
        return;
      }
      const web3authProvider = await web3auth.connect();
      console.log("web3authProvider", web3authProvider)
      setProvider(web3authProvider);
    }
    catch (Err) {
      console.log("Err", Err)
    }
  };


  const getChainId = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const chainId = await rpc.getChainId();
    uiConsole(chainId);
  };

  const getAccounts = async () => {
    try {
      console.log("provider", provider)
      if (!provider) {
        console.log("provider not initialized yet");
        return;
      }
      const rpc = new RPC(provider);
      console.log("rpc")
      const address = await rpc.getAccounts();
      console.log(address);
      return address
    }
    catch (Err) {
      console.log("Err", Err)
    }
  };

  const getBalance = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const balance = await rpc.getBalance();
    uiConsole(balance);
  };

  const sendTransaction = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const receipt = await rpc.sendTransaction();
    uiConsole(receipt);
  };


  const getPrivateKey = async () => {
    if (!provider) {
      uiConsole("provider not initialized yet");
      return;
    }
    const rpc = new RPC(provider);
    const privateKey = await rpc.getPrivateKey();
    uiConsole(privateKey);
  };

  function uiConsole(...args) {
    const el = document.querySelector("#console>p");
    if (el) {
      el.innerHTML = JSON.stringify(args || {}, null, 2);
    }
  }


  const onConnect = async () => {
    try {
      await login()
      let account = await getAccounts()
      console.log("account", account)
      localStorage.setItem("eqx_connected_account", account)
      window.location.reload()
    }
    catch (err) {
      console.log("Err", err)
      throw new Error(err)
    }
  };


  //  disconnect wallet
  const onDisconnect = async () => {
    try {
      await web3auth.logout();
      setProvider(null);
      setProvider(null)
      let multisig_address = getMultiSigWallet()
      const connectedUser = localStorage.getItem("eqx_connected_account")

      localStorage.removeItem("loglevel:http-helpers")
      localStorage.removeItem("wc@2:core:0.3//messages")
      localStorage.removeItem("loglevel:torus-embed")
      localStorage.removeItem("openlogin_store")
      localStorage.removeItem("loglevel:broadcast-channel")
      localStorage.removeItem("loglevel:web3auth-logger")
      localStorage.removeItem("wc@2:core:0.3//keychain")
      localStorage.removeItem("wc@2:client:0.3//proposal")
      localStorage.removeItem("wc@2:core:0.3//subscription")
      localStorage.removeItem("wc@2:core:0.3//history")
      localStorage.removeItem("wc@2:core:0.3//expirer")
      localStorage.removeItem("wc@2:core:0.3//pairing")
      localStorage.removeItem("Web3Auth-cachedAdapter")
      localStorage.removeItem("loglevel:openlogin")
      localStorage.removeItem("eqx_connected_account")
      localStorage.removeItem("selected_chain")
      // localStorage.setItem(connectedUser, multisig_address)
      sessionStorage.clear()
      window.location.reload()
    }
    catch (Err) {
      console.log("Err", Err)
      localStorage.removeItem("loglevel:http-helpers")
      localStorage.removeItem("wc@2:core:0.3//messages")
      localStorage.removeItem("loglevel:torus-embed")
      localStorage.removeItem("openlogin_store")
      localStorage.removeItem("loglevel:broadcast-channel")
      localStorage.removeItem("loglevel:web3auth-logger")
      localStorage.removeItem("loglevel:openlogin")
      localStorage.removeItem("wc@2:core:0.3//keychain")
      localStorage.removeItem("wc@2:client:0.3//proposal")
      localStorage.removeItem("wc@2:core:0.3//subscription")
      localStorage.removeItem("wc@2:core:0.3//history")
      localStorage.removeItem("wc@2:core:0.3//expirer")
      localStorage.removeItem("wc@2:core:0.3//pairing")
      localStorage.removeItem("eqx_connected_account")
      localStorage.removeItem("Web3Auth-cachedAdapter")
      localStorage.removeItem("selected_chain")
      sessionStorage.clear()
      window.location.reload()
    }
  };

  return (
    <>
      {props.mobile ? (
        <div className="button-switch ml-30 mobile">

          <input
            type="checkbox"
            id="switch-blue-mobile"
            className="switch"
            checked={
              localStorage.getItem("eqx_connected_account") !== null
                ? true
                : false
            }
            onChange={() => {
              localStorage.getItem("eqx_connected_account")
                ? onDisconnect()
                : onConnect();
            }}
          />
          <label htmlFor="switch-blue-mobile" className="lbl-off">
            Off&nbsp;Wallet
          </label>
          <label htmlFor="switch-blue-mobile" className="lbl-on">
            On&nbsp;Wallet
          </label>
        </div>
      ) : (

        <>
          <select value={selectedChain} onChange={(e) => {
            e.preventDefault()
            e.persist()
            console.log("selectedChain", e.target.value)
            localStorage.setItem("selected_chain", e.target.value)
            setSelectedChain(e.target.value)
          }}>
            <option value="1">Ethereum</option>
            <option value="56">Binance</option>
            <option value="137">Matic</option>
            <option value="80001">Mumbai</option>
            <option value="97">BscTestnet</option>

          </select>
          <div className="switch">

            <input id="enableWeb2" className="switch__handle switch__handle-round-flat" type="checkbox"
              checked={
                localStorage.getItem("eqx_connected_account") !== null
                  ? true
                  : false
              }
              // defaultChecked
              onChange={() => {
                localStorage.getItem("eqx_connected_account")
                  ? onDisconnect()
                  : onConnect();
              }}
            />
            <label htmlFor="enableWeb2"><div className="switch__handle__text"></div></label>
          </div>
        </>
      )}
      <NetworkError open={wrongNetwork} setOpen={setWrongNetwork} />
    </>
  );
};



export default AccountModal;
