import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTransferRequests } from '../../../../helpers/getterFunctions';

function CompleteRequest(props) {
    const { org } = props;
    const [proposals,setProposals]=useState()
    useEffect(() => {
        getProposals()
      }, [])
    
      const getProposals = async () => {
        let proposals = await getTransferRequests()
        setProposals(proposals?.completedProposals);
      };
    return (
        <div>
            {proposals && proposals?.length>0 && proposals.map((pro, index) => {
                    return (
                        <div className='welcome-card rounded-lg p-6 mb-6 text-black' key={index}>
                            <div className='flex gap-6 justify-between'>
                                <div>
                                    Receiver: {pro.to}
                                    <br />
                                    Description: {pro.description}
                                </div>
                                <div className='self-center'>
                                    Amount: {pro.tokenAmount} {pro.symbol}
                                </div>
                                
                            </div>
                        </div>
                    );
                })
            }
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
      org: state.org,
    };
  };
  
  export default connect(mapStateToProps)(CompleteRequest);