import React, { useEffect } from 'react';
import { useNavigate, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { setMultiSigAdd } from '../../helpers/getterFunctions';

function Home(props) {
    const { auth } = props;
    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/treasury`;
        navigate(path);
    }
    useEffect(() => {
        const fetch = async () => {
            await setMultiSigAdd()
        }
        if(localStorage.getItem("eqx_connected_account"))
        fetch()
    }, [localStorage.getItem("eqx_connected_account")])

    useEffect(() => {
        if (auth?.id) {
            return navigate("/dashboard");
        }
    }, [auth?.id])
    return (
        <div className='main-sec'>
            <div className='container mx-auto px-4 py-20 text-center'>
                <div className=' mb-12 max-lg:text-center'>
                    <h1 className='text-4xl font-bold mb-4 '>
                        Access <span className='text-transparent bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600'>EQ Vault</span>
                    </h1>
                    <p className='text-base text-gray-800'>
                        Decentralized & Permissionless Multi Signature Crypto Vault on BNB Chain.
                    </p>
                </div>
                <div className="grid grid-cols-2 px-16 max-lg:px-0 max-lg:grid-cols-1 gap-6 max-lg:text-center">
                    <div className='p-6 welcome-card rounded-lg shadow-lg'>
                        <div className='mb-3 text-pink-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width="40" height="40" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M12 5l0 14"></path>
                                <path d="M5 12l14 0"></path>
                            </svg>
                        </div>
                        <h3 className='text-3xl font-bold mb-3'>
                            Create EQ Vault
                        </h3>
                        <p className='text-base mb-6'>
                            A new EQ Vault which is controlled collectivey by all members.
                        </p>
                        <button className='inline-block rounded bg-neutral-800 px-6 pt-3 pb-3 text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_#332d2d] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.3),0_4px_18px_0_rgba(51,45,45,0.2)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.3),0_4px_18px_0_rgba(51,45,45,0.2)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.3),0_4px_18px_0_rgba(51,45,45,0.2)] dark:bg-neutral-900 dark:shadow-[0_4px_9px_-4px_#171717] dark:hover:bg-neutral-900 dark:hover:shadow-[0_8px_9px_-4px_rgba(27,27,27,0.3),0_4px_18px_0_rgba(27,27,27,0.2)] dark:focus:bg-neutral-900 dark:focus:shadow-[0_8px_9px_-4px_rgba(27,27,27,0.3),0_4px_18px_0_rgba(27,27,27,0.2)] dark:active:bg-neutral-900 dark:active:shadow-[0_8px_9px_-4px_rgba(27,27,27,0.3),0_4px_18px_0_rgba(27,27,27,0.2)]' onClick={routeChange}>Create new EQ Vault</button>
                    </div>
                    <div className='p-6 welcome-card rounded-lg shadow-lg'>
                        <div className='mb-3 text-pink-500'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-curve-right" width="40" height="40" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M10 7l4 -4l4 4"></path>
                                <path d="M14 3v4.394a6.737 6.737 0 0 1 -3 5.606a6.737 6.737 0 0 0 -3 5.606v2.394"></path>
                            </svg>
                        </div>
                        <h3 className='text-3xl font-bold mb-3'>
                            Add existing EQ Vault
                        </h3>
                        <p className='text-base mb-6'>
                            Already have an EQ Vault? Add your Safe using your Safe address.
                        </p>
                        <Link to='/dashboard/home'><button className=' inline-block rounded bg-slate-100 px-6 pt-3 pb-3 text-xs font-medium uppercase leading-normal text-neutral-800 shadow-[0_4px_9px_-4px_#fbfbfb] transition duration-150 ease-in-out hover:bg-neutral-100 hover:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.3),0_4px_18px_0_rgba(251,251,251,0.2)] focus:bg-neutral-100 focus:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.3),0_4px_18px_0_rgba(251,251,251,0.2)] focus:outline-none focus:ring-0 active:bg-neutral-200 active:shadow-[0_8px_9px_-4px_rgba(251,251,251,0.3),0_4px_18px_0_rgba(251,251,251,0.2)] '>Add existing EQ Vault</button></Link>
                    </div>
                </div>
            </div>

        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
    };
};

export default connect(mapStateToProps)(Home);