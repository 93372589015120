import bep20Abi from "./../Config/abis/bep20Abi.json";
import crowdsaleAbi from "./../Config/abis/crowdsaleAbi.json";
import { getAddress } from "./addressHelper";
import { getAccount, getUserBalance, getWeb3 } from "./currentWalletHelper";
import contracts from "./../Config/contracts";
import { getContractInstance, web3AuthInstance } from "../components/Accountmodal";
import eqxMainAbi from "./../Config/abis/EquinoxMain.json"
import { convertToEth } from "./numberFormatter";
import EventEmitter from "../events";
import RPC from "./../web3RPC"
import deployerAbi from "./../Config/abis/multisigDeployer.json"

export const getCrowdsaleContract = async () => {
  try {
    let web3 = await getWeb3();
    // console.log("here", web3);
    let crowdsale = await new web3.eth.Contract(
      crowdsaleAbi,
      await getAddress(contracts.crowdsale)
    );
    // console.log("crowdsale", crowdsale)
    return crowdsale;
  } catch (e) {
    console.log(e);
  }
};

export const getTokenContract = async (tokenName) => {
  try {
    let web3 = await getWeb3();
    let token = await new web3.eth.Contract(
      bep20Abi,
      await getAddress(contracts[tokenName])
    );
    // console.log(tokenName, token);
    return token;
  } catch (e) {
    console.log(e);
  }
};

export const getTokenBalanceByAddress = async (tokenAddr) => {
  try {
    let contract = await getContractInstance(tokenAddr, bep20Abi)
    let bal = await contract.methods.balanceOf(getMultiSigWallet()).call()
    return convertToEth(bal?.toString());
  }
  catch (err) {
    console.log("err", err)
  }
}

export const getAllowance = async (tokenName, account) => {
  try {
    let web3 = await getWeb3();
    let token = await getTokenContract(tokenName);
    let crowdsaleAddress = await getAddress(contracts.crowdsale);
    let allowance = await token.methods
      .allowance(account, crowdsaleAddress)
      .call();
    // console.log(
    //   "allowance",
    //   await web3.utils.fromWei(allowance.toString(), "ether")
    // );
    return await web3.utils.fromWei(allowance.toString(), "ether");
  } catch (e) {
    console.log(e);
  }
};

export const getTokenBalance = async (tokenName, account) => {
  try {
    let web3 = await getWeb3();
    let token = await getTokenContract(tokenName);
    let balance;
    // if (tokenName == "bnb") {
    //   balance = await getUserBalance();
    // } else

    balance = await token.methods.balanceOf(account).call();
    // console.log(tokenName, balance);
    // console.log(
    //   "balance",
    //   await web3.utils.fromWei(balance.toString(), "ether")
    // );
    return await web3.utils.fromWei(balance.toString(), "ether");
  } catch (e) {
    console.log(e);
  }
};

export const getEquivalentToken = async (tokenToPurchase, tokenName) => {
  try {
    let web3 = await getWeb3();
    let contract = await getCrowdsaleContract();
    tokenToPurchase = await web3.utils.toWei(tokenToPurchase.toString());
    // console.log("token to purchase", tokenToPurchase);
    let amount = await contract.methods
      .amountNeedsToBePaid(tokenToPurchase, contracts[tokenName].type)
      .call();
    // console.log("amount1", amount.toString());
    // console.log("amount", await web3.utils.fromWei(amount, "ether"));
    return (await web3.utils.fromWei(amount, "ether")).toString();
  } catch (e) {
    console.log(e);
  }
};

export const checkIfApproved = async (inputAmount, tokenName, account) => {
  try {
    let allowance = await getAllowance(tokenName, account);
    // console.log("allowances", inputAmount, allowance);
    if (Number(allowance) < Number(inputAmount)) {
      console.log("false");
      return false;
    } else {
      console.log("true");
      return true;
    }
  } catch (e) {
    console.log(e);
  }
};

export const isUserHaveId = async (account) => {
  try {
    let web3 = await getWeb3();
    let contract = await getCrowdsaleContract();
    let id = await contract.methods.usersId(account).call();
    // console.log("id-------->", id)
    return id.toString();
  } catch (e) {
    console.log(e);
  }
};

export const generateRefLink = async (account) => {
  let link;
  let id = await isUserHaveId(account);
  if (Number(id) < 10000) {
    return "";
  }
  link = process.env.REACT_APP_URL + "?id=" + id;
  return link;
  // console.log("ref-link", link,process.env.REACT_APP_URL)
};

export const getCorrespondingEQX = async (amount, tokenName) => {
  try {
    let web3 = await getWeb3();
    let contract = await getCrowdsaleContract();
    amount = await web3.utils.toWei(amount.toString());

    let receivingEQX = await contract.methods
      .getCorrespondingTokens(amount, contracts[tokenName].type)
      .call();
    // console.log("receivingEQX-------->", receivingEQX);
    return (await web3.utils.fromWei(receivingEQX, "ether")).toString();
  } catch (e) {
    console.log(e);
  }
};

export const getCurrId = async () => {
  try {
    let web3 = await getWeb3();
    let contract = await getCrowdsaleContract();
    let id = await contract.methods.currId().call();
    // console.log("currid-------->", id);
    return id.toString();
  } catch (e) {
    console.log(e);
  }
};

export const getCurrPrice = async () => {
  let contract = await getCrowdsaleContract();
  let price = await contract.methods.price().call();
  // console.log("price-------->", price / 1e8);
  return (price / 1e8).toString();
};

export const getTransferRequests = async () => {
  let multiSigAddr = getMultiSigWallet()
  let contract = await getContractInstance(multiSigAddr, eqxMainAbi?.abi)
  console.log("eqx contract", contract)
  let transferProposals = await contract.methods.listTransferProposals().call()
  console.log("transferProposals", transferProposals)
  let updatedTransferProposals = [];
  for (let i = 0; i < transferProposals?.length; i++) {
    let symbol = await getTokenSymbol(transferProposals[i].tokenAddress)
    let tokenAmount = await convertToEth(transferProposals[i]?.tokenAmount)
    let reqId = i
    updatedTransferProposals.push({ ...transferProposals[i], symbol, tokenAmount, reqId })
  }
  console.log("updatedTransferProposals", updatedTransferProposals)
  let pendingProposals = updatedTransferProposals?.filter((p, i) => p.finalized == false && p.disapproved == false)
  let completedProposals = updatedTransferProposals?.filter((p, i) => p.finalized == true && p.disapproved == false)
  let disapprovedProposals = updatedTransferProposals?.filter((p, i) => p.disapproved == true)

  console.log("pedisapprovedProposalsnding", disapprovedProposals)
  return { pendingProposals, completedProposals, disapprovedProposals, transferProposals }
}

export const getTokenSymbol = async (address) => {
  let token = await getContractInstance(address, bep20Abi)
  console.log("token", token)
  let symbol = await token.methods.symbol().call()
  console.log("Symbol", symbol)
  return symbol
}

export const getMultiSigWallet = () => {
  // let deployer = await getContractInstance(getAddress(contracts?.multisigDeployer), deployerAbi)
  // let multisigAddress = await deployer.methods.memberToMultisig(localStorage.getItem("eqx_connected_account")).call()
  return localStorage.getItem(localStorage.getItem("eqx_connected_account")+"-"+localStorage.getItem("selected_chain"))
  // return multisigAddress
}


export const setMultiSigAdd = async () => {
  try {
    let deployer = await getContractInstance(getAddress(contracts?.multisigDeployer), deployerAbi)
    console.log("multisig deployer", deployer)
    if (localStorage.getItem("eqx_connected_account")) {
      let multisigAddress = await deployer.methods.memberToMultisig(localStorage.getItem("eqx_connected_account")).call()
      localStorage.setItem(localStorage.getItem("eqx_connected_account")+"-"+localStorage.getItem("selected_chain"), multisigAddress);
      return multisigAddress
    }
  }
  catch (Err) {
    console.log("Err", Err)
  }
}

export const getSelectedChainData = () => {
  let chainId = ""
  let networkId = ""
  let rpc = ""
  let network = "testnet"
  let clientId = ""
  if (localStorage.getItem("selected_chain") == "1") {
    chainId = "0x1"
    networkId = "1"
    rpc = "https://eth.llamarpc.com"
    network = "mainnet"
    clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"
  }
  else if (localStorage.getItem("selected_chain") == "97") {
    chainId = "0x61"
    networkId = "97"
    rpc = "https://data-seed-prebsc-1-s3.binance.org:8545"
    network = "testnet"
    clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"
  }
  else if (localStorage.getItem("selected_chain") == "56") {
    chainId = "0x38"
    networkId = "56"
    rpc = "https://bsc-dataseed.binance.org"
    network = "mainnet"
    clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"
  }
  else if (localStorage.getItem("selected_chain") == "137") {
    chainId = "0x89"
    networkId = "137"
    rpc = "https://polygon.llamarpc.com"
    network = "mainnet"
    clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"
  }
  else if (localStorage.getItem("selected_chain") == "80001") {
    chainId = "0x13881"
    networkId = "80001"
    rpc = "https://rpc-mumbai.maticvigil.com/"
    network = "testnet"
    clientId = "BF1tfolzZ5uLXezVOb02qkQfJNohiueDwZyMRIEwuvc5P06-tPOof_G1W3IxQgGFn1l9j87Xsr8D7mgaSfF-OSM"
  }

  return {
    chainId,
    networkId,
    rpc,
    network,
    clientId
  }
}

export const checkWalletConditions = async () => {
  let savedAccount = localStorage.getItem("eqx_connected_account")
  let actualAccount = await getAccount()
  console.log("savedAccount", savedAccount, actualAccount)
  if (savedAccount?.toLowerCase() !== actualAccount?.toLowerCase()) {
    alert("Wrong Account")
  }
  let provider = await web3AuthInstance()
  let rpc = new RPC(provider)
  let currentChainId = await rpc.getChainId()
  console.log("currentChainId", currentChainId)
  if (localStorage.getItem("selected_chain") !== currentChainId?.toString()) {
    let eventEmitter = new EventEmitter()
    alert("Wrong Network")
    eventEmitter.emit("wrongNetwork")
  }
}

export const getAddedTokens = async () => {
  let assets = localStorage.getItem(`tokens-${localStorage.getItem("selected_chain")}-${localStorage.getItem("eqx_connected_account")}`)
  let tokensArray = []

  if (assets) {
    tokensArray = [...JSON.parse(assets)]
  }
  for (let i = 0; i < tokensArray?.length; i++) {
    let balance = await getTokenBalanceByAddress(tokensArray[i]?.tokenAddress)
    tokensArray[i].balance = balance
  }
  return tokensArray
}

export const getTeamMembers = async () => {
  let contract = await getContractInstance(getMultiSigWallet(), eqxMainAbi?.abi)
  let members = await contract.methods.listSigners().call()
  members = members.filter((mem) => mem !== "0x0000000000000000000000000000000000000000")
  console.log("signers", members)
  return members;
}

export const getAddedMembers = async () => {
  let contract = await getContractInstance(getMultiSigWallet(), eqxMainAbi?.abi)
  let members = await contract.methods.listAddedMembers().call()
  let updatedProposals = [];
  for (let i = 0; i < members?.length; i++) {
    let reqId = i
    updatedProposals.push({ ...members[i], reqId })
  }
  let pendingRequests = updatedProposals?.filter((mem) => mem?.finalized == false && mem?.disapproved == false)
  let completedRequests = updatedProposals?.filter((mem) => mem?.finalized == true)
  let disapprovedRequests = updatedProposals?.filter((mem) => mem?.finalized == false && mem?.disapproved == true)
  console.log("add member requests", pendingRequests, completedRequests, disapprovedRequests)
  return { pendingRequests, completedRequests, disapprovedRequests };
}

export const getRemoveMembers = async () => {
  let contract = await getContractInstance(getMultiSigWallet(), eqxMainAbi?.abi)
  let members = await contract.methods.listRemoveMembers().call()
  let updatedProposals = [];
  for (let i = 0; i < members?.length; i++) {
    let reqId = i
    updatedProposals.push({ ...members[i], reqId })
  }
  let pendingRequests = updatedProposals?.filter((mem) => mem?.finalized == false && mem?.disapproved == false)
  let completedRequests = updatedProposals?.filter((mem) => mem?.finalized == true)
  let disapprovedRequests = updatedProposals?.filter((mem) => mem?.finalized == false && mem?.disapproved == true)
  console.log("add member requests", pendingRequests, completedRequests, disapprovedRequests)
  return { pendingRequests, completedRequests, disapprovedRequests };
}

