import Web3 from "web3";
import { getWeb3 } from "./currentWalletHelper";

export function convertToInternationalCurrencySystem(labelValue) {
    return Math.abs(Number(labelValue)) >= 1.0e9 ?
      (Math.abs(Number(labelValue)) / 1.0e9).toFixed(5) + "B" :
      Math.abs(Number(labelValue)) >= 1.0e6 ?
      (Math.abs(Number(labelValue)) / 1.0e6).toFixed(5) + "M" :
      Math.abs(Number(labelValue)) >= 1.0e3 ?
      (Math.abs(Number(labelValue)) / 1.0e3).toFixed(5) + "K" :
      Math.abs(Number(labelValue)).toFixed(5);
  }

export const toFixed = (x) => {
    if (Math.abs(x) < 1.0) {
      var e = parseInt(x.toString().split('e-')[1]);
      if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
      }
    } else {
      var e = parseInt(x.toString().split('+')[1]);
      if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
      }
    }
    return x;
  }

  export const convertToEth = async(num)=>{
    let web3 = await getWeb3()
    return web3.utils.fromWei(num,"ether")
  }
  