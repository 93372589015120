import { Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import ConfirmModal from "../../../../components/modals/ConfirmModal";
import { getAccount, getWeb3 } from "../../../../helpers/currentWalletHelper";
import multiSigv2Abi from "./../../../../Config/abis/EquinoxMain.json";
import store from "../../../../redux/store";
import { updateSpinner } from "../../../../redux/actions";
import { getMultiSigWallet, getTransferRequests } from '../../../../helpers/getterFunctions';
import { getContractInstance } from '../../../../components/Accountmodal';

function PendingRequest(props) {
  const { org, auth } = props;
  const [currentValues, setCurrentValues] = useState({});
  const [transferApproveModal, setTransferApproveModal] = useState(false);
  const [transferDissApproveModal, setTransferDissApproveModal] = useState(false);
  const [transferFinalizeModal, setTransferFinalizeModal] = useState(false);
  const [view, setView] = useState(false);
  const [voters, setVoter] = useState([]);
  const [votingLoading, setVotingLoading] = useState(false);
  const [message, setMessage] = useState("Vote has been registered");
  const [errPopup, setErrPopup] = useState(false);
  const [action, setAction] = useState(false);
  const [proposals, setProposals] = useState([]);
  const [reload, setReload] = useState(false)
  const [selectedPropId, setSelectedPropId] = useState()

  const getVotingList = async (pro, type) => {
    if (org && org?.members?.length > 0) {
      setVotingLoading(true);

      setVoter([]);
      org.members
        .filter((member) => member.is_active === 1)
        .forEach(async (element) => {
          let objectOfMember = {};
          axios
            .get(
              `${process.env.REACT_APP_API_URL}/get_vote_list_by_type/${type}/${element.id}`
            )
            .then(({ data }) => {
              console.log(data, pro);
              if (data.status === "success") {
                const matchedVales = data.response.filter(
                  (item) => item.proposal_id === pro?.id
                );
                console.log("Matched", matchedVales);
                objectOfMember = element;
                if (matchedVales.length > 0) {
                  objectOfMember.voted = "Yes";
                } else {
                  objectOfMember.voted = "No";
                }
              }
              if (data.status === "error") {
                objectOfMember = element;
                objectOfMember.voted = "No";
              }

              setVoter((prev) => [...prev, objectOfMember]);
              setVotingLoading(false);
            })
            .catch((error) => {
              setVotingLoading(false);
              console.log(error);
              // setLoading(false);
            });
        });
      console.log("calling");
      // setLoading(false);
      setView(true);
    }
  };

  useEffect(() => {
    getProposals()
  }, [reload])

  const getProposals = async () => {
    let proposals = await getTransferRequests()
    setProposals(proposals?.pendingProposals);
  };
  const transferApproveHandler = (values) => {
    console.log(values);
    setTransferApproveModal(false);
    approve(values.to, selectedPropId);
  };

  const transferDissApproveHandler = (values) => {
    console.log(values);
    setTransferDissApproveModal(false);
    disApprove(values.to, selectedPropId);
  };
  const transferFinalizeHandler = (values) => {
    console.log(values);
    setTransferFinalizeModal(false);
    finalize(values.to, values.tokenAddress, selectedPropId);
  };
  const approve = async (to, id) => {
    try {
      let account = await getAccount();
      console.log("here")
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );
      const isSigned = await contract.methods
        .isTransferProposalSigned(id, account)
        .call();
      console.log(isSigned);
      const isDisapprovedSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      if (isDisapprovedSigned) {
        alert("Proposal has been disapproved by one of the team member");
      } else {
        if (!isSigned) {
          store.dispatch(updateSpinner(true));

          await contract.methods
            .signTransferProposal(id)
            .send({ from: account })
            .on("error", (error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            })
            .then(async (result) => {
              console.log(result);
              store.dispatch(updateSpinner(false));
              setMessage("Your Vote has been registered");
              setAction(true);
              setTimeout(() => {
                setAction(false);
              }, 500);
            });
        } else {
          alert("Vote has already been registered");
        }
      }
    }
    catch (Err) {
      console.log("Err", Err)
    }
  };
  const disApprove = async (to, id) => {
    try {
      let web3 = await getWeb3();

      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );
      const isAprroved = await contract.methods
        .isTransferProposalSigned(id, account)
        .call();
      const isSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      console.log(isSigned);
      if (isAprroved) {
        alert("Your vote already registered");
      } else {
        if (!isSigned) {
          store.dispatch(updateSpinner(true));

          await contract.methods
            .disapproveTransferProposal(id)
            .send({ from: account })
            .on("error", (error) => {
              console.log(error);
              store.dispatch(updateSpinner(false));
            })
            .then(async (result) => {
              // const response = await axios.post(
              //   `${process.env.REACT_APP_API_URL}/add_vote`,
              //   {
              //     member_id: auth.id,
              //     org_id: org?.org?.id,
              //     proposal_id: id,
              //     proposal_type: 4,
              //     vote: 0,
              //   }
              // );
              // console.log("Vote Done", response);
              console.log(result);
              store.dispatch(updateSpinner(false));
              setMessage("Your Vote has been registered");
              setAction(true);
              setTimeout(() => {
                setAction(false);
              }, 500);
            });
        } else {
          alert("Proposal has been disapproved");
        }
      }
    }
    catch (Err) {
      console.log("Err", Err)
    }
  };

  const finalize = async (to, tokenAddress, id) => {
    try {
      let web3 = await getWeb3();

      let account = await getAccount();
      let multiSigAddr = getMultiSigWallet();
      const contract = await getContractInstance(
        multiSigAddr,
        multiSigv2Abi.abi
      );
      // const {
      //   data: { response: indexListOftransferProposal },
      // } = await axios.post(`${process.env.REACT_APP_API_URL}/get_index_list`, {
      //   org_id: org?.org?.id,
      //   type: "transfer_proposal",
      // });
      // console.log(indexListOftransferProposal);

      // const propIndex =
      //   indexListOftransferProposal?.filter(
      //     (val) => Number(val.data) === id
      //   )[0].index_number - 1;
      // console.log(propIndex);
      const isFullySigned = await contract.methods
        .transferProposalSignerRequirementMet(id)
        .call();
      console.log(isFullySigned);
      const isDisapprovedSigned = await contract.methods
        .isTransferProposalDisapproved(id, account)
        .call();
      if (isDisapprovedSigned) {
        alert("Proposal has been disapproved");
      } else {
        if (isFullySigned) {
          console.log(to);
          store.dispatch(updateSpinner(true));

          await contract.methods
            .finalizeTransferProposal(id)
            .send({ from: account })
            .on("error", (error) => {
              store.dispatch(updateSpinner(false));
              console.log(error);
              setErrPopup(true);
            })
            // .then((result) => {
            //   console.log(result);
            //   axios
            //     .post(`${process.env.REACT_APP_API_URL}/finalize_fund_transfer`, {
            //       id: id,
            //       status: 1,
            //     })
            .then((res) => {
              console.log(res);
              store.dispatch(updateSpinner(false));
              setMessage("Proposal Finalize successfully");
              setAction(true);
              // setTimeout(() => {
              //   setAction(false);
              //   window.location.reload();
              // }, 500);
            });
          setReload(!reload)
          // });
        } else {
          alert("All members have not approved this transaction");
        }
      }
    }
    catch (Err) {
      console.log("Err", Err)
    }
  };
  return (
    <div>
      {proposals && proposals?.length > 0 && proposals?.map((pro, index) => {
        console.log("proposals", proposals)
        return (
          <div className='welcome-card rounded-lg p-6 mb-6 text-black'>
            <div className='flex gap-6 justify-between'>
              <div>
                Receiver: {pro.to}
                <br />
                Description: {pro.description}
              </div>
              <div>
                Amount: {pro.tokenAmount} {pro.symbol}
                {/* {org.project[0].token_name} */}
              </div>
              <div>
                {!pro.finalized && (
                  <span
                    onClick={() => {
                      setCurrentValues(pro);
                      setSelectedPropId(pro?.reqId)
                      setTransferApproveModal(true);
                    }}
                    className="approve"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="text-green-600 icon icon-tabler icon-tabler-check" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12l5 5l10 -10"></path>
                    </svg>
                  </span>
                )}
                {pro.finalized && (
                  <span
                    // onClick={() => approve(index)}
                    className="approved"
                  >
                    Approved
                  </span>
                )}
              </div>
              <div onClick={() => {
                setCurrentValues(pro);
                setSelectedPropId(pro?.reqId)
                setTransferDissApproveModal(true);
              }}>
                <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M18 6l-12 12"></path>
                  <path d="M6 6l12 12"></path>
                </svg>
              </div>
              <div>
                3 Days
              </div>
              <div className='flex gap-3'>
                {!pro.finalized && (<Button type='primary' className='grad-btn border-0' onClick={() => {
                  setCurrentValues(pro);
                  setSelectedPropId(index)
                  setTransferFinalizeModal(true);
                }}>Finalize</Button>)}
                <Button type='primary' className='grad-btn border-0' onClick={() => {
                  setCurrentValues(pro);
                  getVotingList(pro, 4);
                }}>View</Button>
              </div>
            </div>
          </div>
        );
      })}
      <ConfirmModal
        title="Are you sure you want to Approve!"
        // note=""
        // note_bracket=""
        open={transferApproveModal}
        setOpen={setTransferApproveModal}
        handler={transferApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Disapprove!"
        // note=""
        // note_bracket=""
        open={transferDissApproveModal}
        setOpen={setTransferDissApproveModal}
        handler={transferDissApproveHandler}
        currentValues={currentValues}
      />
      <ConfirmModal
        title="Are you sure you want to Finalize!"
        // note=""
        // note_bracket=""
        open={transferFinalizeModal}
        setOpen={setTransferFinalizeModal}
        handler={transferFinalizeHandler}
        currentValues={currentValues}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    org: state.org,
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(PendingRequest);