export const ACCOUNT_UPDATE = "ACCOUNT_UPDATE";
export const WEB3_LOADED = "WEB3_LOADED";
export const ACCOUNT_UPDATE_ON_DISCONNECT = "ACCOUNT_UPDATE_ON_DISCONNECT";
export const SWAP_SUCCESS = "SWAP_SUCCESS"
export const SWAP_SUCCESS_RESET = "SWAP_SUCCESS_RESET"
export const ADD_LIQUIDITY_SUCCESS = "ADD_LIQUIDITY_SUCCESS"
export const ADD_LIQUIDITY_SUCCESS_RESET = "ADD_LIQUIDITY_SUCCESS_RESET"
export const STAKE_UNSTAKE_SUCCESS = "STAKE_UNSTAKE_SUCCESS"
export const STAKE_UNSTAKE_SUCCESS_RESET = "STAKE_UNSTAKE_SUCCESS_RESET"
export const REMOVE_LIQUIDITY_SUCCESS = "REMOVE_LIQUIDITY_SUCCESS"
export const REMOVE_LIQUIDITY_SUCCESS_RESET = "REMOVE_LIQUIDITY_SUCCESS_RESET"
export const ADD_ORG_FORMDATA = "ADD_ORG_FORMDATA"
export const ADD_PROJECT_FORMDATA = "ADD_PROJECT_FORMDATA"
export const ADD_PROPOSAL_FORMDATA = "ADD_PROPOSAL_FORMDATA"
export const LOAD_DETAILS = "LOAD_DETAILS"
export const LOAD_CATEGORY = "LOAD_CATEGORY"
export const LOAD_PROJECT = "LOAD_PROJECT"
export const LOAD_ORG = "LOAD_ORG"
export const ADD_ICO_FORMDATA = "ADD_ICO_FORMDATA"
export const LOAD_ICOS = "LOAD_ICOS"
export const LOAD_PROPOSALS = "LOAD_PROPOSALS"
export const LOAD_VOTED_LIST = "LOAD_VOTED_LIST"
export const LOAD_MEMBER_VOTED_LIST = "LOAD_MEMBER_VOTED_LIST"
export const UPDATE_SPINNER = "UPDATE_SPINNER"
export const GET_CONFIG = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
export const POST_CONFIG = {
  headers: {
    "Content-Type": "application/json",
  },
};

export const URL = "http://localhost:3001";
